function $exceptionHandler($window) {
    "ngInject";
    return function( exception, cause )
    {
        if(process.env.NODE_ENV !== 'development') {
            $window.bugsnagClient.notify(new Error(exception));
            throw exception;
        }
    };
}

module.exports = $exceptionHandler;