function StatusCodeService ( $rootScope )
{
    "ngInject";

    return {
        setStatusCode: function (code) {
            $rootScope.statusCode = code;
        }
    }
}

module.exports = StatusCodeService;