function NoLinkDirective($timeout) {
    "ngInject";

    return {
        restrict: 'A',
        link: function($scope, element, attrs) {
            $timeout(function () {
                $('.product-description a').removeAttr("href");
            }, 0);
        }
    }
}



module.exports = NoLinkDirective;