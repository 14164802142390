function FreeDeliveryRegionsService (RequestService) {
  'ngInject'

  var getFreeDeliveryRegions = function (catalogItemId) {
    var data = {
      request: 'getFreeDeliveryRegions',
      catalogItemId: catalogItemId,
    }

    return RequestService.processRequest('/api/handler.php', data)
  }

  return {
    getFreeDeliveryRegions: getFreeDeliveryRegions,
  }
}

module.exports = FreeDeliveryRegionsService