var angular = require( "angular" );

angular.module( "flowersApp" )
    .component("catalogFilter", require('./CatalogFilterComponent'))
    .component("catalogFilterFieldBoolean", require('./CatalogFilterFieldBooleanComponent'))
    .component("catalogFilterFieldRelation", require('./CatalogFilterFieldRelationComponent'))
    .component("catalogFilterFieldPrice", require('./CatalogFilterFieldPriceComponent'))
    .component("reviewsItem", require('./ReviewsItemComponent'))
    .component("randomSeoLinks", require('./RandomSeoLinksComponent'))
    .component("freeDeliveryRegions", require('./FreeDeliveryRegionsComponent'))
    .component("catalogOrder", require('./CatalogOrderComponent'));