function BouquetOnPhotoController ( $scope, $anchorScroll, Notification, CategoriesService, BouquetOnPhotoService, CatalogItemService, AppConfigService, AdditionalGoodsService, SuperFastOrderService, Upload, RequestService, MetaService, StatusCodeService )
{
    "ngInject";

    var vm = this;
    vm.goToAnchor = $anchorScroll;
    vm.deliveryForm = undefined;
    vm.superFastOrder = SuperFastOrderService;
    vm.bouquetService = BouquetOnPhotoService;
    vm.AdditionalGoods = AdditionalGoodsService;

    vm.options = undefined;
    vm.orderData = undefined;
    vm.orderSum = undefined;

    vm.termsOfUse = undefined;

    vm.showPromoForm = false;

    vm.images = [];

    vm.uploadedImg = [];

    vm.catalogItemWatch =  $scope.$watch(
        function ()
        {
            return CatalogItemService.options.itemData;
        },
        function ( newValue, oldValue )
        {
            if ( newValue != oldValue && typeof ( newValue ) == "object" )
            {
                vm.init();
            }
        }
    );

    vm.setWatchers = function ()
    {

        $scope.$watch(
            function ()
            {
                return vm.orderData.selectedDelivery;
            },
            function ( newValue, oldValue )
            {

                if ( ( typeof ( newValue ) != "undefined" ) && ( typeof ( AppConfigService.options.config.selfdelivery_points ) != "undefined" ) )
                {

                    if ( newValue.selfdelivery == false )
                    {
                        vm.orderData.selectedDelivery.selfdelivery_point = "";
                    }

                }
            });

        $scope.$watch(
            function ()
            {
                return vm.orderData.selectedDelivery.selfdelivery_point;
            },
            function ( newValue, oldValue )
            {
                if( newValue != oldValue )
                {
                    vm.bouquetService.getWorkingHours();
                }
            });


        $scope.$watch(
            function ()
            {
                return vm.options.schedule;
            },
            function ( newValue, oldValue )
            {
                if ( vm.orderData.date != null )
                {
                    vm.bouquetService.prepareWorkingHours( vm.orderData.date );
                }
            }
        );

    }

    vm.onDateSelected = function ( newDate, oldDate )
    {
        vm.bouquetService.prepareWorkingHours ( newDate );
    };

    vm.beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate )
    {
        vm.bouquetService.beforeCalendarRender ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate );
    }

    vm.checkPromoCode = function ()
    {
        vm.bouquetService.checkPromoCode();
    }

    vm.rejectPromoCode = function ()
    {
        vm.bouquetService.rejectPromoCode();
    }

    vm.doPurchase = function ()
    {
        if (!vm.deliveryForm.$valid || !vm.deliveryForm.delivery_customer_name.$valid || !vm.deliveryForm.delivery_phone_number.$valid)
        {
            Notification.error("Заполните обязательные поля");
            return false;
        }

        vm.bouquetService.saveImagesPath(vm.uploadedImg);

        if ( vm.orderData.images.length === 0)
        {
            Notification.error("Вы не загрузили ни одной фотографии");
            return false;
        }

        if ( !vm.orderData.date || !vm.orderData.time )
        {
            Notification.error("Выберите дату и время доставки");
            return false;
        }

        if (!vm.termsOfUse.isCheckTerms)
        {
            return false;
        }

        vm.bouquetService.doPurchase();
    };

    vm.isCardInAdditionalGoods = function ()
    {
        return vm.bouquetService.isCardInAdditionalGoods();
    };

    vm.getDateTimePickerConfig = function ()
    {
        return vm.bouquetService.getDateTimePickerConfig();
    };

    vm.init = function ()
    {
        vm.catalogItemWatch();

        vm.bouquetService.clearOrderData().then( function( result )
        {
            vm.options = vm.bouquetService.options ();
            vm.orderData = vm.bouquetService.orderData ();
            vm.orderSum = vm.bouquetService.orderSum ();

            vm.bouquetService.getDeliveryTypes();
            vm.bouquetService.getDeliveryRegions();
            vm.setWatchers();
        });

        StatusCodeService.setStatusCode('200');
        MetaService.setMetaTags({
            title: "Букет по фото, Доставка цветов " + AppConfigService.options.config.city,
            description: AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords,
            img: AppConfigService.options.config.logo
        });

        CategoriesService.states.activeItem = undefined;
    };

    vm.init();

    vm.prepareToUpload = function (images) {
        if (images && images.length) {
            for (var i = 0; i < images.length; i++) {
                if ( images[i].isUpload ) continue;
                vm.uploadFile(images[i], i);
                images[i].isUpload = true;
            }
        }
    };

    vm.uploadFile = function (image, i) {
        Upload.upload({
            url: 'api/handler.php',
            method: 'POST',
            file: image,
            data: {
                request: 'loadImg'
            }
        }).success(function (response) {
            vm.uploadedImg.push(response.data.path);
        });
    };


    vm.removeImg = function (image) {
        vm.images.splice(vm.images.indexOf(image), 1);

        var name = image.name.split('.');
        var path = '';
        vm.uploadedImg.forEach(function (value, index, array) {
            if ( value.indexOf(name[0]) + 1 ) {
                path = value;
                array.splice(array.indexOf(value), 1)
            }
        });

        vm.deleteFromServer(path);
    };

    vm.deleteFromServer = function (path) {

        var data = {
            request: 'deleteImg',
            data: path
        };

        RequestService.processRequest("/api/handler.php", data);
    };
}

module.exports = BouquetOnPhotoController;