function CatalogItemService ( $routeParams, $location, MetaService, StatusCodeService, AppConfigService, RequestService )
{
    "ngInject";

    var options = {
        loading: {
            catalogItem: true
        },
        itemData: {}
    };

    var getCatalogItemData = function () {
        sessionStorage.setItem("itemId", $routeParams.itemId);
        options.loading.catalogItem = true;
        options.itemData = {};

        var data = {
            request: 'getCatalogItemById',
            catalogItemId: $routeParams.itemId
        };

        return RequestService.processRequest("/api/handler.php", data).success ( function ( response )
        {
                response = response.data;
                options.loading.catalogItem = false;
                options.itemData = response.itemData;

                sendGtagInfo(response.itemData);

                StatusCodeService.setStatusCode('200');

                MetaService.setMetaTags({
                    title: "Заказать " + ((options.itemData.buket) ? "Букет " : "" ) + options.itemData.name + " в городе " + AppConfigService.options.config.city,
                    description: "Хочешь заказать " + options.itemData.category  + "? Купить " + ((options.itemData.buket) ? "Букет " : "" ) + options.itemData.name + " с доставкой по городу " + AppConfigService.options.config.city + " от салона " + AppConfigService.options.config.shop_name,
                    keywords: AppConfigService.options.config.system_keywords,
                    img: options.itemData.image
                });
        }).error( function(){$location.url( "/404" );});
    };

    var init = function ()
    {
        getCatalogItemData()
    };

    var sendGtagInfo = function (data) {

        var item = {
            "id": data.id,
            "name": data.name,
            "list_name": data.category,
            "category": data.category,
            "quantity": 1,
            "price": data.price
        };

        gtag('event', 'select_content', {
            "content_type": "product",
            "items": [item]
        });
        gtag('event', 'view_item', {
            "items": [item]
        });
    };

    return {
        options: options,
        init: init,
        getCatalogItemData: getCatalogItemData,
    }

}
module.exports = CatalogItemService;