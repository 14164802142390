function AddToCartDirective() {
    "ngInject";

    return {
        link: function($scope, element, attrs) {
            element.bind('click', function(event) {
                var that = $(this).closest('.product-item').find('img');
                if (that.offset() === undefined) {
                    that = $('.product-information img:first-child').first();
                }
                var basket = $(".basket");
                var w = that.width();

                that.clone()
                    .css({'width' : w,
                        'position' : 'absolute',
                        'z-index' : '9999',
                        top: that.offset().top,
                        left:that.offset().left})
                    .appendTo("body")
                    .animate({opacity: 0.05,
                        left: basket.offset()['left'],
                        top: basket.offset()['top'],
                        width: 20}, 1000, function() {
                        $(this).remove();
                    });
            })
        }
    }
}

module.exports = AddToCartDirective;