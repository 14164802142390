function AdditionalGoodsController ( $scope, AdditionalGoodsService, AppConfigService )
{
    "ngInject";

    var vm = this;
    vm.additionalGoods = AdditionalGoodsService;
    vm.appConfig = AppConfigService;

    vm.addItem = function ( item )
    {
        vm.additionalGoods.addItem( item );
    };

    vm.isItemExist = function ( item )
    {
        return vm.additionalGoods.isItemExist( item );
    };

    vm.openCategoryWindow = function ( categoryId )
    {
        vm.additionalGoods.openCategoryWindow( categoryId );
    };

    vm.closeCategoryWindow = function ()
    {
        vm.additionalGoods.closeCategoryWindow();
    };

    vm.getCategorySum = function ( categoryId )
    {
        return vm.additionalGoods.getCategorySum( categoryId );
    };

    vm.init = function (cart)
    {
        vm.additionalGoods.service.items = [];

        vm.additionalGoods.getCategoriesList(cart);
    };
}

module.exports = AdditionalGoodsController;