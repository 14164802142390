function NotFoundService ( $timeout, StatusCodeService, MetaService, AppConfigService )
{
    "ngInject";
    var mainPage = {
        link: "/"
    };

    var init = function ()
    {
        MetaService.setMetaTags({
            title: "Страница не найдена, Доставка цветов " + AppConfigService.options.config.city,
            description: AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords,
            img: AppConfigService.options.config.logo
        });

        $timeout(function () {
            StatusCodeService.setStatusCode('404');
        },150)

    };

    return {
        mainPage: mainPage,
        init: init
    }
}

module.exports = NotFoundService;