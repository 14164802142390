function FancyBoxDirective() {
    "ngInject";

    return {
        restrict: 'A',

        link: function(scope, element, attrs){
            $(element).fancybox({

            });
        }
    }
}



module.exports = FancyBoxDirective;