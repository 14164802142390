function CartController ( $scope, $anchorScroll, $location, Notification, SuperFastOrderService, CartService, AdditionalGoodsService, AppConfigService, StatusCodeService, MetaService)
{
    "ngInject";

    var vm = this;
    vm.deliveryForm = undefined;
    vm.superFastOrder = SuperFastOrderService;
    vm.cartService = CartService;
    vm.AdditionalGoods = AdditionalGoodsService;

    vm.options = undefined;
    vm.orderData = undefined;
    vm.orderSum = undefined;

    vm.termsOfUse = undefined;

    vm.showPromoForm = false;
    vm.scroll = false;

    vm.setWatchers = function ()
    {

        $scope.$watch(
            function ()
            {
                return vm.orderData.selectedDelivery;
            },
            function ( newValue, oldValue )
            {
                if ( ( typeof ( newValue ) != "undefined" ) && ( typeof ( AppConfigService.options.config.selfdelivery_points ) != "undefined" ) && ( newValue !== oldValue) )
                {

                    if ( newValue.selfdelivery == false )
                    {
                        vm.orderData.selectedDelivery.selfdelivery_point = '';
                    }
                    vm.cartService.updateOrderPrice();
                    vm.cartService.getAvailablePayments();
                }
            });

        $scope.$watch(
            function ()
            {
                return vm.orderData.needPhoto;
            },
            function ( newValue, oldValue )
            {
                vm.cartService.updateOrderPrice();
            });

        $scope.$watch(
            function ()
            {
                return vm.orderData.selectedDelivery.selfdelivery_point;
            },
            function ( newValue, oldValue )
            {
                if( newValue != oldValue )
                {
                    vm.cartService.getWorkingHours();
                }
            });

        $scope.$watch(
            function ()
            {
                return vm.AdditionalGoods.service.items;
            },
            function ( newValue, oldValue )
            {
                if ( newValue != oldValue )
                {
                    vm.cartService.updateOrderPrice();
                }
            },
            true
        );

        $scope.$watch(
            function ()
            {
                return vm.options.schedule;
            },
            function ( newValue, oldValue )
            {
                if ( vm.orderData.date != null )
                {
                    vm.cartService.prepareWorkingHours( vm.orderData.date );
                }
            }
        );

    };

    vm.onDateSelected = function ( newDate, oldDate )
    {
        vm.cartService.prepareWorkingHours ( newDate );
    };

    vm.beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate )
    {
        vm.cartService.beforeCalendarRender ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate );
    };

    vm.checkPromoCode = function ()
    {
        vm.cartService.checkPromoCode();
    };

    vm.rejectPromoCode = function ()
    {
        vm.cartService.rejectPromoCode();
    };

    vm.updateOrderPrice = function ()
    {
        vm.cartService.updateOrderPrice();
    };

    vm.doPurchase = function ()
    {

        if ( !vm.deliveryForm.$valid || ( typeof ( vm.orderData.delivery_region.id ) == "undefined" && vm.orderData.selectedDelivery.selfdelivery == false ) || !vm.orderData.payment_id)
        {
            Notification.error("Заполните обязательные поля");
            return false;
        }

        if ( !vm.orderData.date || !vm.orderData.time )
        {
            Notification.error("Выберите дату и время доставки");
            return false;
        }

        if (!vm.termsOfUse.isCheckTerms)
        {
            return false;
        }

        vm.cartService.doPurchase();
    };

    vm.isCardInAdditionalGoods = function ()
    {
        return vm.cartService.isCardInAdditionalGoods();
    };

    vm.getDateTimePickerConfig = function ()
    {
        return vm.cartService.getDateTimePickerConfig();
    };

    vm.init = function ()
    {
        moment.locale("ru");
        vm.options = vm.cartService.options();
        vm.orderData = vm.cartService.orderData();
        vm.orderSum = vm.cartService.orderSum();

        vm.cartService.getDeliveryTypes();
        vm.cartService.getDeliveryRegions();
        vm.setWatchers();

        StatusCodeService.setStatusCode( '200' );

        MetaService.setMetaTags({
            title: "Корзина, Доставка цветов " + AppConfigService.options.config.city,
            description: AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords
        });
    };

    vm.init();

    vm.clearCart = function()
    {
        swal({
            text: "Вы действительно хотите очистить корзину?",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
            dangerMode: true,
            buttons: {
                cancel: {
                    text: "Отмена",
                    visible: true
                },
                confirm: {
                    text: "Да"
                }
            }
        }).then(function (willDelete) {
            if (willDelete) {
                vm.cartService.clearCart();
                Notification.info("Корзина очищена!");
                $location.hash('up');
                $anchorScroll();
            }
        });
    };

    vm.disablePhoto = function (value) {
        if (value === "Самовывоз") vm.orderData.needPhoto = false;
    }

}

module.exports = CartController;
