function CatalogItemListService ($interpolate, $routeParams, $location, MetaService, StatusCodeService, PriceFilterService, AppConfigService, RequestService) {

    "ngInject";

    var catalog = {
        id: undefined,
        currentPage: 0,
        isEndPage: true,
        isLoading: false,
        catalogName: "",
        totalPagesCount: 0,
        price: {
//            min: null,
//            max: null
        },
        items: [],
        seoContent: "",
    };

    var setMetaData = true;
    var priceFilter = PriceFilterService;

    /*
        Метод для "ленивой" подгрузки элементов каталога
     */
    var lazyLoadItems = function ()
    {
        if ( catalog.isLoading || catalog.isEndPage || !catalog.id) {
            return;
        }

        var requestParams = {
            'request': 'getCatalogItemsById',
            'categoryId': catalog.id,
            'currentPage': catalog.currentPage
        };

        Object.assign(requestParams, $location.search())

        catalog.isLoading = true;

        RequestService.processRequest("/api/handler.php", requestParams, 'get').success(function (data)
        {
            data = data.data;

            var catalogName = data.catalogName ? data.catalogName : 'Акции';

            sendGtagInfo(data.items, catalogName);

            var recievedItems = data.items;
            catalog.isEndPage = data.isEndPage;
            if( !catalog.isEndPage ) {
                catalog.currentPage++;
            }

            Array.prototype.push.apply(catalog.items, recievedItems);

            catalog.isLoading = false;
        }).error( function (){$location.url( "/404" );});
    }

    /*
        Старый метод для загрузки всех элементов каталога
     */
    var loadCatalogItems = function () {

        catalog.items = [];

        catalog.currentPage = 0;

        var requestParams = {
            'request': 'getCatalogItemsById',
            'categoryId': catalog.id,
            'currentPage': catalog.currentPage
        };

        Object.assign(requestParams, $location.search())

        catalog.isLoading = true;

        RequestService.processRequest("/api/handler.php", requestParams, 'get').success( function ( data )
        {
            data = data.data;

            catalog.items = data.items;
            catalog.isEndPage = data.isEndPage;
            catalog.totalPagesCount = data.totalPagesCount;
            catalog.seoContent = (typeof data.seoContent === 'undefined') ? '' : data.seoContent

            catalog.catalogName = ''

            if (data.catalogName) {
                let catalogNameInterpolate = $interpolate(data.catalogName)
                catalog.catalogName = catalogNameInterpolate(AppConfigService.options.config)
            } else {
                catalog.catalogName = 'Акции'
            }

            sendGtagInfo(data.items, catalog.catalogName);

            if (!catalog.isEndPage) {
                catalog.currentPage++;
            }

            catalog.isLoading = false;

            if (setMetaData == true) {
                let title = (typeof data.seoTitle === 'undefined') ? '' : data.seoTitle,
                    description = (typeof data.seoMetaDescription === 'undefined') ? '' : data.seoMetaDescription,
                    keywords = (typeof data.seoMetaKeywords === 'undefined') ? '' : data.seoMetaKeywords

                setMeta(title, description, keywords);
            }
            StatusCodeService.setStatusCode( '200' );

        }).error( function ( data, status, headers, config )
        {
            $location.url( "/404" );

        });
    };

    var initCatalogList = function ( catalogId, updateMetaData )
    {
        if ( typeof( updateMetaData ) != "boolean" )
        {
            updateMetaData = true;
        }

        setMetaData = updateMetaData;

        var oldCatalogId = catalog.id;

        catalog.id = !catalogId ? $routeParams.categoryId : catalogId;

        if (catalog.id != oldCatalogId) {
            catalog.items = null;

            loadCatalogItems();
        } else {
            setMeta();
        }
    };

    var doFilterCatalog = function ()
    {
        loadCatalogItems();
    }

    var catalogFilter = function ( item )
    {
        if ( typeof( item ) == "undefined" )
        {
            return false;
        }

        //return catalog.id == 68 ? item.price <= priceFilter.filterValue[1] && item.price >= priceFilter.filterValue[0] : true;
        return true
    };

    var setMeta = function (title, description, keywords) {

        var metaData = {
            title: ((typeof (catalog.catalogName) != "undefined") ? catalog.catalogName : "Букеты") + ", Доставляем букеты по городу " + AppConfigService.options.config.city,
            description: "Хочешь заказать " + ((typeof (catalog.catalogName) != "undefined") ? catalog.catalogName : "Букеты") + "? " + ((typeof (catalog.catalogName) != "undefined") ? catalog.catalogName : "Букеты") + " c доставкой по городу " + AppConfigService.options.config.city + " от салона " + AppConfigService.options.config.shop_name,
            keywords: AppConfigService.options.config.system_keywords
        }

        if (title !== '' && title !== null) {
            metaData.title = title
        }

        if (description !== '' && description !== null) {
            metaData.description = description
        }

        if (keywords !== '' && keywords !== null) {
            metaData.keywords = keywords
        }

        MetaService.setMetaTags(metaData);
    };

    var sendGtagInfo = function (data, category) {

        var items = [];
        data.forEach(function (item) {
            items.push({
                "id": item.id,
                "name": item.name,
                "list_name": category,
                "category": category,
                "quantity": 1,
                "price": item.price
            });
        });

        gtag('event', 'view_item_list', {
            "items": items
        });
    };

    return {
        options: catalog,
        initCatalogList: initCatalogList,
        loadCatalogItems: loadCatalogItems,
        lazyLoadItems: lazyLoadItems,
        catalogFilter: catalogFilter,
        doFilterCatalog: doFilterCatalog,
    }
}

module.exports = CatalogItemListService;