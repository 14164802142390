function CityButtonDirective() {
    "ngInject";

    return {
        link: function($scope, element, attrs) {
            setInterval(function () {
                element.toggleClass('button-city', true);
            }, 2000)
        }
    }
}



module.exports = CityButtonDirective;