function ReviewsItemController( AppConfigService ) {
  "ngInject";

  var vm = this;
  vm.appConfig = AppConfigService;
}

module.exports = {
  templateUrl: '/angular_pages/includes/reviews_item_template.tpl?' + __webpack_hash__,
  controller: ReviewsItemController,
  controllerAs: 'reviewsItemCtrl',
  bindings: {
    review: '<'
  }
}