function CloseSearchDirective() {
    "ngInject";

    return {
        link: function($scope, element, attrs) {
            element.bind('click', function(event) {
                if (event.target.id !== undefined && event.target.id !== 'search') {
                    angular.element(document.querySelector('.dropdown')).removeClass('open');
                }
            })
        }
    }
}



module.exports = CloseSearchDirective;