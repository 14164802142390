var angular = require("angular");

angular.module( "flowersApp" )
    .controller( "AppController", require( "./AppController" ) )
    .controller( "IndexController", require( "./IndexController" ) )
    .controller( "CatalogItemListController", require( "./CatalogItemListController" ) )
    .controller( "ContentController", require( "./ContentController" ) )
    .controller( "NotFoundController", require( "./NotFoundController" ) )
    .controller( "BannersController", require( "./BannersController" ) )
    .controller( "CategoriesController", require( "./CategoriesController" ) )
    .controller( "CatalogItemController", require( "./CatalogItemController" ) )
    .controller( "SuccessPaymentController", require( "./SuccessPaymentController" ) )
    .controller( "AdditionalGoodsController", require( "./AdditionalGoodsController" ) )
    .controller( "PriceFilterController", require( "./PriceFilterController" ) )
    .controller( "SupplementController", require( "./SupplementController") )
    .controller( "CollectController", require( "./CollectController" ) )
    .controller( "CollectModalController", require( "./CollectModalController" ) )
    .controller( "SuperFastOrderController", require( "./SuperFastOrderController" ) )
    .controller( "MaintenanceController", require( "./MaintenanceController" ) )
    .controller( "SearchController", require( "./SearchController" ) )
    .controller( "BouquetOnPhotoController", require( "./BouquetOnPhotoController" ) )
    .controller( "CartController", require( "./CartController" ) )
    .controller( "ReviewsController", require( "./ReviewsController" ) )
    .controller( "SamePriceController", require( "./SamePriceController" ) )
    .controller( "SeoMenuController", require( "./SeoMenuController" ) )
    .controller( "CookieNotificationController", require( "./CookieNotificationController" ) )

    .controller( "CatalogFilterComponentController", require( "./CatalogFilterComponentController" ) );