function SuccessPaymentService ( $routeParams, $location, $route, StatusCodeService, RequestService, MetaService, AppConfigService )
{
    "ngInject";

    var values = {};

    var init = function () {
        values.orderNumber = null;
        values.orderSum = null;
        values.option = $routeParams.option;
        var getParams = $location.search();
        var data = null;

        StatusCodeService.setStatusCode('200');

        if (typeof($routeParams.sum) != "undefined") {
            values.orderSum = $routeParams.sum;
        }

        if (typeof($routeParams.OutSum) != "undefined") {
            values.orderSum = $routeParams.OutSum;
        }

        switch (true) {
            case typeof($routeParams.orderNumber) != "undefined":
                values.orderNumber = $routeParams.orderNumber;
                break;

            case (typeof(getParams.order_id) !== "undefined" && getParams.order_id != 0):
                data = {
                    request: 'getOrderNumber',
                    orderObjectId: getParams.order_id
                };
                RequestService.processRequest("/api/handler.php", data).success(function (response) {
                    response = response.data;
                    if (response) {
                        values.orderNumber = response.orderNumber;
                    }
                });
                break;

            case typeof($routeParams.comment) != "undefined":
                var commentObject = JSON.parse( $routeParams.comment );

                data = {
                    request: 'getOrderNumber',
                    orderObjectId: commentObject.order_id
                };

                values.orderSum = commentObject.order_sum;

                RequestService.processRequest("/api/handler.php", data).success(function (response){
                    response = response.data;
                    if(response){
                        values.orderNumber = response.orderNumber;
                    }
                });
                break;

            case typeof($routeParams.Shp_numberId) != "undefined":
                values.orderNumber = $routeParams.Shp_numberId;
                break;
        }

        MetaService.setMetaTags({
            title: "Заказ №" + values.orderNumber + " в городе " + AppConfigService.options.config.city,
            description: "Оформлен заказ №" + values.orderNumber + " на сумму " + values.orderSum + " руб." + " в городе " + AppConfigService.options.config.city + ". " + AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords
        });
    };

    return {
        values: values,
        init: init
    }
}

module.exports = SuccessPaymentService;