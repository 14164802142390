function SamePriceController( $routeParams, RequestService )
{
    "ngInject";

    var vm = this;
    vm.samePriceProducts = [];
    vm.samePriceProductsLoaded = false;

    vm.loadCatalogItems = function ()
    {
        vm.popularProductsLoaded = false;

        var data = {
            request: 'getSamePriceGoods',
            limitPerPage: 4,
            itemId: $routeParams.itemId
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            vm.samePriceProducts = response.data;
            vm.samePriceProductsLoaded = true;
        }).error(function() {
            vm.samePriceProductsLoaded = true;
        });
    };

    vm.initPopularBlock = function()
    {
        vm.loadCatalogItems();
    }
}

module.exports = SamePriceController;