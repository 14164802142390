require('./vendor');

if(process.env.NODE_ENV !== 'development') {
    window.bugsnagClient = bugsnag("17597b84dfdd5f189e25f775a0c8c35d");
}


angular.module('flowersApp', ['ui.bootstrap-slider', 'ngRoute', 'ui.bootstrap', 'ngSanitize', 'ui.bootstrap.datetimepicker', 'yaMap', 'ui.mask', 'ngAnimate', 'ui-notification', 'ng', 'yandex-metrika', 'ng.deviceDetector', 'ngCookies', 'infinite-scroll', 'angular-global-resolve', 'ngFileUpload', 'StrongSmoothScroll'])
.config( function ( $httpProvider, $routeProvider, $locationProvider, NotificationProvider, globalResolveProvider )
{
    'ngInject';

    $httpProvider.defaults.withCredentials = true;

    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix('!');

    globalResolveProvider.addGlobalDependenciesTo( $routeProvider, {
        getGlobalDependacies: [ "AppService", function ( AppService ) {
            return AppService.initApp();
        }]
    });

    $routeProvider
        .when('/', {
            templateUrl: "/angular_pages/index_content.tpl?" + __webpack_hash__,
            controller: "IndexController",
            controllerAs: "indexCtrl"
        })

        .when('/maintenance', {
            templateUrl: '/angular_pages/maintenance.tpl?' + __webpack_hash__,
            controller: "MaintenanceController",
            controllerAs: "mCtrl"
        })

        .when('/search', {
            templateUrl: '/angular_pages/search_page.tpl?' + __webpack_hash__,
            controller: "SearchController",
            controllerAs: "searchCtrl"
        })

        .when('/collect', {
            templateUrl: '/angular_pages/collect.tpl?' + __webpack_hash__,
            controller: 'CollectController',
            controllerAs: 'collectCtrl'
        })

        .when('/bouquet-on-photo', {
            templateUrl: '/angular_pages/bouquet_on_photo.tpl?' + __webpack_hash__
        })

        .when('/cart', {
            templateUrl: '/angular_pages/cart.tpl?' + __webpack_hash__
        })

        /* Route for reviews */
        .when('/reviews', {
            templateUrl: '/angular_pages/reviews.tpl?' + __webpack_hash__,
            controller: 'ReviewsController',
            controllerAs: 'reviewsCtrl'
        })
        /* Route for reviews */

        .when( '/404', {
            templateUrl: '/angular_pages/404.tpl?' + __webpack_hash__,
            controller: 'NotFoundController',
            controllerAs: 'notFoundCtrl'
        })

        /* Supplement result */

        .when('/supplement/success/', {
            templateUrl: '/angular_pages/supplement_success.tpl?' + __webpack_hash__,
            controller: 'SupplementController',
            controllerAs: 'supCtrl'
        })

        .when('/supplement/fail/', {
            templateUrl: '/angular_pages/supplement_fail.tpl?' + __webpack_hash__,
            controller: 'SupplementController',
            controllerAs: 'supCtrl'
        })

        /* !Supplement result */

        /* Bouquet on Photo result */

        .when('/bop/success/', {
            templateUrl: '/angular_pages/bop_success.tpl?' + __webpack_hash__,
            controller: 'SupplementController',
            controllerAs: 'supCtrl'
        })

        .when('/bop/fail/', {
            templateUrl: '/angular_pages/bop_fail.tpl?' + __webpack_hash__,
            controller: 'SupplementController',
            controllerAs: 'supCtrl'
        })

        /* !Bouquet on Photo result */

        .when('/catalog/:categoryId', {
            templateUrl: '/angular_pages/inner_catalog_items.tpl?' + __webpack_hash__,
            controller: "CatalogItemListController",
            controllerAs: "catalogItemListCtrl"
        })

        .when('/catalog/:categoryId/:minPrice/:maxPrice', {
            templateUrl: '/angular_pages/inner_catalog_items.tpl?' + __webpack_hash__,
            controller: "CatalogItemListController",
            controllerAs: "catalogItemListCtrl"
        })

        .when('/item/:itemId', {
            templateUrl: '/angular_pages/inner_catalog_item_view.tpl?' + __webpack_hash__,
            controller: 'CatalogItemController',
            controllerAs: 'catalogItemCtrl'
        })

        .when('/page/:name', {
            templateUrl: '/angular_pages/content.tpl?' + __webpack_hash__,
            controller: 'ContentController',
            controllerAs: 'contentCtrl'
        })

        .when('/success/', {
            templateUrl: '/angular_pages/order_success.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when('/success/:option/', {
            templateUrl: '/angular_pages/order_success.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when('/success/:option/:orderNumber', {
            templateUrl: '/angular_pages/order_success.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when('/success/:option/:orderNumber/:sum', {
            templateUrl: '/angular_pages/order_success.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .otherwise({
            templateUrl: '/angular_pages/404.tpl?' + __webpack_hash__,
            controller: 'NotFoundController',
            controllerAs: 'notFoundCtrl'
        });

    NotificationProvider.setOptions(
    {
        delay: 5000,
        startTop: 20,
        startRight: 10,
        verticalSpacing: 20,
        horizontalSpacing: 20,
        positionX: 'center',
        positionY: 'bottom'
    });
})
.run(["$rootScope", "$location", "RequestService", "$routeParams", "AppConfigService", '$timeout', function($rootScope, $location, RequestService, $routeParams, AppConfigService) {

    $rootScope.$on("$routeChangeStart", function() {
        if (AppConfigService.options.maintenanceMode === true) {
            $location.url('/maintenance');
        }

        if ($location.$$url === "/maintenance" && AppConfigService.options.maintenanceMode === false) {
            $location.url('/');
        }
    });
}]);

require("./directives");
require("./components");
require("./controllers");
require("./services");

/* App Styles */
require('./../templates/demoold/css/styles.css');