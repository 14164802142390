function CatalogFilterComponentService ($location, RequestService) {
  'ngInject'

  let getFilterFields = function (catalogItemId) {
    let requestParams = {
      request: 'getCatalogFilter',
      categoryId: catalogItemId,
    }

    Object.assign(requestParams, $location.search())
    return RequestService.processRequest('/api/handler.php', requestParams, 'get')
  }

  return {
    getFilterFields: getFilterFields,
  }
}

module.exports = CatalogFilterComponentService