function CookieNotificationController ($cookies)
{
    'ngInject';

    var vm = this;

    vm.SetTermsOfUseCookie = function () {
        $cookies.put('termsOfUseCookie', 'true');
    };

    vm.GetTermsOfUseCookie = function () {
        return $cookies.get('termsOfUseCookie') !== undefined;
    };
}

module.exports = CookieNotificationController;