function CategoriesController ( AppConfigService, CategoriesService )
{
    "ngInject";

    var vm = this;

    vm.appConfig = AppConfigService;
    vm.category = CategoriesService;
}

module.exports = CategoriesController;