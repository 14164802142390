function MaintenanceController (MaintenanceService, AppConfigService)
{
    'ngInject';

    var vm = this;

    vm.masterConfig = AppConfigService;

    vm.maintenanceOn = function () {
        MaintenanceService.setMeta();
    }

}

module.exports = MaintenanceController;