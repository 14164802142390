function CollectService ( $location, StatusCodeService, MetaService, AppConfigService, $uibModal, RequestService, CartService )
{
    "ngInject";

    var config = {
        wishes : "",
        collectionCart : [],
        collections : [],
        currentCollection : {}
    };

    var loadCollections = function ()
    {
        if ($location.$$url === "/collect" && AppConfigService.options.config && AppConfigService.options.config.collect == null) {
            $location.url('/404');
        }

        StatusCodeService.setStatusCode( '200' );

        MetaService.setMetaTags({
            title: "Собрать букет, Доставка цветов по городу " + AppConfigService.options.config.city,
            description: "Собери свой авторский букет из разных цветов с уникальным оформлением. Салон " + AppConfigService.options.config.shop_name + " в городе " + AppConfigService.options.config.city,
            keywords: AppConfigService.options.config.system_keywords
        });

        var data = {
            request: 'getCollections'
        };
        RequestService.processRequest("/api/handler.php", data).success(function (response)
        {
            config.collections = response.data;
        });
    };

    var openCollectionItemsModal = function (collection)
    {
        config.currentCollection = collection;
        var modalInstance = $uibModal.open({
            animation: true,
            windowClass: 'collection-modal',
            templateUrl: 'collectModal.html',
            controller: 'CollectModalController',
            controllerAs: 'collectModCtrl'
        });
    };

    var changeItemAmount = function (item, type)
    {
        switch (type) {
            case 'minus':
            {
                if (itemInCollectionCart(item)) {
                    if (item.amount <= 1) {
                        removeFromCollectionCart(item);
                    }
                    item.amount--;
                }
                break;
            }

            case 'plus':
            {
                if (itemInCollectionCart(item)) {
                    item.amount++;
                } else {
                    addToCollectionCart(item);
                }
                break;
            }
        }
    };

    var addToCollectionCart = function (item)
    {
        item.amount = 1;
        config.collectionCart.push(item);
    };

    var itemInCollectionCart = function (item)
    {
        return config.collectionCart.filter(function (i)
        {
            return i.id == item.id;
        })[0];
    };

    var getCollectionSum = function ()
    {
        var sum = 0;
        config.collectionCart.map(function (item)
        {
            sum += item.amount * item.price;
        });
        return sum;
    };

    var removeFromCollectionCart = function (item)
    {
        item.amount = 0;
        config.collectionCart = config.collectionCart.filter(function (i)
        {
            return i.id != item.id;
        });
    };

    var collectBouquet = function ()
    {
        var data = {
            request: 'collectBouquet',
            items: config.collectionCart,
            wishes: config.wishes
        };
        RequestService.processRequest("/api/handler.php", data).success(function (response) {
            response = response.data;

            if (response.itemData) {
                CartService.addToCart(response.itemData);
                config.collectionCart = [];
                window.location.href = "/cart";
            }
        });
    };

    return {
        config:config,
        loadCollections: loadCollections,
        removeFromCollectionCart: removeFromCollectionCart,
        getCollectionSum: getCollectionSum,
        changeItemAmount: changeItemAmount,
        openCollectionItemsModal: openCollectionItemsModal,
        collectBouquet: collectBouquet
    };
}

module.exports = CollectService;