function RandomSeoLinksService (RequestService) {
  'ngInject'

  let getRandomSeoLinks = function (linksCount, catalogItemId) {
    let requestParams = {
      request: 'getRandomSeoFilterLinks',
      linksCount: linksCount,
      catalogItemId: catalogItemId
    }

    return RequestService.processRequest('/api/handler.php', requestParams, 'get')
  }

  return {
    getRandomSeoLinks: getRandomSeoLinks
  }
}

module.exports = RandomSeoLinksService