function template () {
  return `
    <a ng-repeat="item in $ctrl.data.items track by item.id" ng-href="{{item.link}}" title="{{item.title}}">
        #{{item.title}}<span ng-if="$last === false">, </span>
    </a>
  `
}

function RandomSeoLinksController (RandomSeoLinksService) {
  'ngInject'

  let vm = this

  vm.data = {
      items: []
  }

  vm.$onInit = function () {
    RandomSeoLinksService.getRandomSeoLinks(4, vm.catalogItemId).then(function (response) {
      vm.data.items = response.data.data.items
    })
  }
}

module.exports = {
  template: template(),
  controller: RandomSeoLinksController,
  bindings: {
    catalogItemId: '<'
  }
}