function CategoriesService ( $q, RequestService )
{
    "ngInject";

    var items = undefined;

    var states = {
        activeItem: undefined
    };

    var getList = function ()
    {
        var deffered = $q.defer();

        var data = {
            request: 'getCategories',
            v2: true
        };

        RequestService.processRequest("/api/handler.php", data).then( function ( response ) {
            response = response.data.data;
            items = response;
            deffered.resolve( items );
        }, function ( error ) {
            items = undefined;
            deffered.reject( error );
        });

        return deffered.promise;
    };

    return {
        states: states,
        items: items,
        getList: getList
    }
}

module.exports = CategoriesService;