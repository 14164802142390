function ReviewsController( $location, ReviewsService, MetaService, AppConfigService ) {
  "ngInject";

  var vm = this;
  vm.reviews = [];
  vm.loading = false;
  vm.isEndPage = false;
  vm.page = 1;
  vm.appConfig = AppConfigService;

  vm.getReviews = function () {
    if( vm.isEndPage ) {
      return;
    }

    vm.loading = true;
    ReviewsService.getReviews( vm.page ).then( function ( response ) {
      var responseData = response.data;
      Array.prototype.push.apply( vm.reviews, responseData.data );

      vm.isEndPage = ( responseData.meta.current_page == responseData.meta.last_page );
      ++vm.page;
      vm.loading = false;
    }).catch( function () {
      vm.loading = false;
      vm.isEndPage = true;
    });
  }

  var setMetaData = function () {
    MetaService.setMetaTags({
      title: "Отзывы, Доставка цветов " + AppConfigService.options.config.city,
    });
  }

  vm.init = function () {

     if( AppConfigService.options.config.reviews_shop_token == null ) {
       $location.url( "/404" );
       return;
     }

    setMetaData();
    vm.getReviews();
  }
}

module.exports = ReviewsController;
