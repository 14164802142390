function RequestService ( $http )
{
    "ngInject";

    var action = "/api/handler.php";

    var processRequest = function ( url, data, method ) {
        data = typeof data !== 'undefined' ?  data : null;

        switch (method) {
            case 'get': {
                return sendGetRequest(url, data );
                break;
            }
            case 'post': {}
            default : {
                return sendPostRequest(url, data );
            }
        }
    };

    var sendPostRequest = function (url, data ) {
        return $http.post( action, data)
    };

    var sendGetRequest = function (url, data) {
        return $http.get(action, {
            params: data
        })
    }

    return {
        processRequest : processRequest
    }
}

module.exports = RequestService;