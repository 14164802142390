function PriceFilterController ( $routeParams, PriceFilterService, CatalogItemListService )
{
    "ngInject";

    var vm = this;
    vm.priceFilterService = PriceFilterService;
    vm.catalog = CatalogItemListService;

    vm.init = function ()
    {
        vm.priceFilterService.init();
        
        if ( typeof( $routeParams.minPrice ) != "undefined" && typeof( $routeParams.maxPrice ) != "undefined" )
        {
            vm.priceFilterService.options.value = [ parseInt( $routeParams.minPrice ), parseInt( $routeParams.maxPrice ) ];
        }
    };

    vm.init();
}

module.exports = PriceFilterController;