function SupplementController ( StatusCodeService, AppConfigService )
{

    'ngInject';
    var vm = this;

    vm.config = AppConfigService;

    StatusCodeService.setStatusCode( '200' );
}

module.exports = SupplementController;