/* Vendor Libs */
require('angular');
require('angular-locale');
require("angular-animate");
require("angular-route");
require("angular-global-resolve");
require("angular-cookies");
require("angular-ui-bootstrap");
require("angular-ui-notification");
require("ng-infinite-scroll");
require("angular-bootstrap-datetimepicker");
require("moment");
require("re-tree");
require("ua-device-detector");
require("angular-sanitize");
require("ng-device-detector");
require("ng-file-upload");
require("bootstrap-slider");
require("angular-bootstrap-slider");
require("jquery");
require("@fancyapps/fancybox");
require("jquery-ui");
require("bootstrap");
require('sweetalert');
require("angularjs-yandex-metrika");

require("./../templates/demoold/js/angular-modules/ng-device-detector.min.js");
require("./../templates/demoold/js/yandex-map.js");
require("./../templates/demoold/js/ui-bootstrap-tpls-2.0.0.min.js");
require("./../templates/demoold/js/angular-translate.min.js");
require("./../templates/demoold/js/slideout.min.js");
require("./../templates/demoold/js/mask.min.js");
require("./../templates/demoold/js/strong-smooth-scroll.js");
require("./../templates/demoold/js/bioep.js");

/* Vendor Styles */
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('angular-ui-notification/dist/angular-ui-notification.min.css');
require('bootstrap/dist/css/bootstrap.min.css');
require("./../templates/demoold/css/datetimepicker.min.css");
require("./../templates/demoold/css/bootstrap-slider.min.css");
require('./../templates/demoold/css/smart-app-banner.css');