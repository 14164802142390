function BannersController ( BannersService )
{
    'ngInject';

    var vm = this;

    vm.service = BannersService;
    vm.slidesInterval = 3000;
}

module.exports = BannersController;