function SeoMenuService (RequestService) {

    "ngInject";

    var seoMenu = {
        seoMenuLinks: []
    };

    var getSeoMenu = function () {
        var requestParams = {
            request: 'getSeoMenu'
        }

        RequestService.processRequest('/api/handler.php', requestParams).success(function (response) {
            seoMenu.seoMenuLinks = response.data;
        });
    };

    return {
        getSeoMenu: getSeoMenu,
        seoMenu: seoMenu
    }

}

module.exports = SeoMenuService;