function SuperFastOrderService ( $uibModal, Notification, RequestService )
{
    'ngInject';

    var options = {
        clientPhone: '',
        itemData: '',
        fastOrderProcessing: false
    };

    var modalInstance;

    var oneClickOrderForm = function (items) {
        options.itemData = items;
        options.clientPhone = '';
        modalInstance = $uibModal.open({
            animation: true,
            windowClass: 'one-click-order-form',
            size: 'sm',
            templateUrl: "/angular_pages/includes/one_click_order_form.tpl",
            controller: 'SuperFastOrderController as fastCtrl'
        });
    };

    var getItems = function () {
        var items = [];

        items.push(options.itemData);
        return items;
    };

    var makeFastOrder = function () {

        if (options.clientPhone === '') {
            Notification.error("Введите номер телефона");
            return false;
        }

        var items = getItems();
        options.fastOrderProcessing = true;

        var data = {
            request: 'oneClickOrder',
            data: {
                clientPhone: options.clientPhone
            },
            items: items
        };

        RequestService.processRequest("/api/handler.php", data).success(function ( response )
        {
            response = response.data;
            if( response.result === false ) {
                Notification.error("Неверно введен номер телефона");
                options.fastOrderProcessing = false;

                return false;
            }

            modalInstance.close();

            window.location.href = response.redirect;

            options.fastOrderProcessing = false;
        });
    };

    return {
        options: options,
        makeFastOrder: makeFastOrder,
        getItems: getItems,
        oneClickOrderForm: oneClickOrderForm
    };
}

module.exports = SuperFastOrderService;