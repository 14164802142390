function SearchService ( $location, RequestService )
{
    "ngInject";

    var options = {
        searchString: '',
        currentPage: 0,
        isEndPage: true,
        isLoading: false,
        result: null,
        items: null,
        totalCount: null,
        search: null
    };

    var lazyLoadItems = function ()
    {

        if ( options.isLoading || options.isEndPage) {
            return;
        }

        var requestParams = {
            'request': 'search',
            'limit': 10,
            'string' : options.search,
            'p': options.currentPage
        };

        options.isLoading = true;

        RequestService.processRequest("/api/handler.php", requestParams).success(function (response)
        {
            response = response.data;

            var recievedItems = response.items;
            options.isEndPage = response.isEndPage;
            if( !options.isEndPage ) {
                options.currentPage++;
            }

            Array.prototype.push.apply(options.items, recievedItems);

            options.isLoading = false;
        }).error( function (){$location.url( "/404" );});
    };

    var search = function (value) {

        if (value.length >= 3) {
            angular.element(document.querySelector("#floatingCirclesG")).css('display', 'block');
            var data = {
                'request' : 'search',
                'string' : value,
                'limit' : 6
            };

            options.search = value;

            RequestService.processRequest("/api/handler.php", data).success(function (response) {
                    angular.element(document.querySelector("#floatingCirclesG")).css('display', 'none');
                    if (response) {
                        response = response.data;
                    } else return;

                    angular.element(document.querySelector(".dropdown")).addClass('open');
                    options.result = response.items;
                    options.totalCount = response.totalElementsCount;
                    options.isEndPage = data.isEndPage;
                });
        } else {
            options.result = null;
            angular.element(document.querySelector('.dropdown')).removeClass('open');
        }
    };

    var clearSearch = function () {
        angular.element(document.querySelector('.dropdown')).removeClass('open');
        options.searchString = '';
        options.result = null;
    };

    var openSearch = function () {
        var elem = angular.element(document.querySelector(".dropdown"));
        if (options.result) {
            elem.addClass('open');
        }
    };

    var openSearchPage = function () {
        options.items = [];
        options.items = options.result;
    };

    var declOfNum = function (number, titles) {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5] ];
    };

    var countFoundedItems = function () {
        var titlesFound = ['найден', 'найдено', 'найдено'];
        var titlesGoods = ['товар', 'товара', 'товаров'];
        var str = 'Всего ' + declOfNum(options.totalCount, titlesFound) + ' ' + options.totalCount + ' ' + declOfNum(options.totalCount, titlesGoods);

        return str;
    };


    return {
        options: options,
        search: search,
        clearSearch: clearSearch,
        openSearch: openSearch,
        openSearchPage: openSearchPage,
        lazyLoadItems: lazyLoadItems,
        countFoundedItems: countFoundedItems
    }
}

module.exports = SearchService;