function template () {
  return `
    <div class="{{catalogFilterFieldBooleanCtrl.componentUUID}}">
        <div class="dropdown" ng-class="{open: catalogFilterFieldBooleanCtrl.open}">
            <button type="button" class="btn btn-link btn-block dropdown-toggle" ng-click="catalogFilterFieldBooleanCtrl.toggleDropdown()">
                <span class="pull-left">            
                    {{::catalogFilterFieldBooleanCtrl.field.title}} 
                    <span class="badge" ng-if="(catalogFilterFieldBooleanCtrl.field.selected == 1 || catalogFilterFieldBooleanCtrl.field.selected == true)">
                        {{(catalogFilterFieldBooleanCtrl.field.selected == 1 || catalogFilterFieldBooleanCtrl.field.selected == true)? 'да' : 'нет'}}
                    </span>
                </span>
                <span>
                    &nbsp;                
                </span>
                <span>
                   <span class="caret"></span>
                </span>
            </button>
            <ul class="dropdown-menu">
                <li>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" value="1" name="fields_filter[{{catalogFilterFieldBooleanCtrl.field.name}}]" ng-checked="catalogFilterFieldBooleanCtrl.field.selected == 1 || catalogFilterFieldBooleanCtrl.field.selected == true" ng-click="catalogFilterFieldBooleanCtrl.toggleChecked()" />
                            Да
                        </label>
                    </div>
                </li>
            </ul>        
        </div>      
    </div>
`;
}

function CatalogFilterFieldBooleanController ($scope) {
  'ngInject'

  let vm = this

  vm.componentUUID = ''
  vm.open = false
  vm.customEvent = false

  vm.toggleDropdown = function () {
    if (vm.open) {
      vm.open = false
    } else {
      vm.addEventListener()
      vm.open = true
    }
  }

  vm.toggleChecked = function () {
    if (vm.field.selected != 1) {
      vm.field.selected = 1
    } else {
      vm.field.selected = 0
    }
  }

  vm.addEventListener = function () {
    document.addEventListener('click', vm.closeOnClickOutsideEvent, false)
  }

  vm.removeEventListener = function () {
    document.removeEventListener('click', vm.closeOnClickOutsideEvent, false)
  }

  vm.closeOnClickOutsideEvent = function (event) {
    if (!event.target.closest(
      'catalog-filter-field-boolean .' + vm.componentUUID)) {
      vm.removeEventListener()
      $scope.$apply(function () {
        vm.open = false
      })
    }
  }

  vm.generateUUID = function () {
    return 'component-uuid' + Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  }

  vm.$onInit = function () {
    vm.componentUUID = vm.generateUUID()
  }

  vm.$onDestroy = function () {
    vm.removeEventListener()
  }
}

module.exports = {
  template: template(),
  controller: CatalogFilterFieldBooleanController,
  controllerAs: 'catalogFilterFieldBooleanCtrl',
  bindings: {
    field: '=',
  },
}