function BouquetOnPhotoService ( $q, $window, $location, AdditionalGoodsService, AppConfigService, RequestService, MetaService )
{
    "ngInject";

    var options = {
        schedule: {},
        exceptions: [],
        timePeriods: [],
        deliveryRegions: [],
        deliveryTypes: [],
        paymentMethods: [],
        formSubmitting: false,
        loading: {
            price: false,
            forming: false,
            deliveryRegions: false,
            timePicker: false
        },
        lastSeenItems: [],
        dateTimePickerConfig: {
            startView: 'day',
            minView: 'day'
        }
    };

    var orderData = {
        selectedDelivery: {},
        is_anonymous: false,
        delivery_customer_name: "",
        delivery_client_name: "",
        customer_phone_number: "",
        phone_number: "",
        delivery_region: null,
        delivery_address: "",
        date: null,
        time: null,
        promo_code: null,
        promo_error: null,
        promo_type: null,
        promo_sum: null,
        cardText: "",
        noNeedText: false,
        order_comment: "",
        images: []
    };

    var termsOfUse = {
        isCheckTerms: true
    }

    var orderSum = {
        discountId: null,
        promoSum: 0
    };

    var temp = {
        images: []
    };

    var setMeta = function (title, description, keywords) {

        var metaData = {
            title: "Букет по фото, Доставка цветов по городу " + AppConfigService.options.config.city,
            description: "Знаешь что хочешь, но не нашел на сайте? Оформи заказ по фото! Мы с радостью выполним твой заказ. Салон " + AppConfigService.options.config.shop_name + " в городе " + AppConfigService.options.config.city,
            keywords: AppConfigService.options.config.system_keywords
        }

        MetaService.setMetaTags(metaData);
    };

    var getDateTimePickerConfig = function ()
    {
        setMeta();
        return options.dateTimePickerConfig;
    };

    var clearOrderData = function ()
    {
        var deferred = $q.defer();

        orderData = {
            selectedDelivery: {},
            is_anonymous: false,
            delivery_customer_name: "",
            delivery_client_name: "",
            customer_phone_number: "",
            phone_number: "",
            delivery_region: null,
            delivery_address: "",
            date: null,
            time: null,
            promo_code: null,
            promo_error: null,
            promo_type: null,
            promo_sum: null,
            payment_id: null,
            cardText: "",
            noNeedText: false,
            order_comment: "",
            images: []
        };

        deferred.resolve( true );

        return deferred.promise;
    };

    var isCardInAdditionalGoods = function ()
    {
        var result = false;

        AdditionalGoodsService.service.items.map( function ( catalog )
        {
            catalog.map( function ( item )
            {
                if ( item.type == "card" )
                {
                    result = true;
                }
            });
        });

        return result;
    };

    var getOrderItems = function ()
    {
        var orderItems = [];

        AdditionalGoodsService.service.items.map( function ( catalogCategory ) {
            catalogCategory.map( function ( item ) {
                orderItems.push( item );
            });
        });

        return orderItems;
    };

    var getDeliveryTypes = function ()
    {
        options.loading.deliveryTypes = true;
        var data = {
            request: 'getDeliveryTypes'
        };

        return RequestService.processRequest("/api/handler.php", data).success(function (response)
        {
            options.loading.deliveryTypes = false;
            options.deliveryTypes = response.data;

            if ( options.deliveryTypes.length > 0 )
            {
                orderData.selectedDelivery = options.deliveryTypes[1];
            }

            // if( AppConfigService.options.config.selfdelivery_points.length > 0 )
            // {
            //     orderData.selectedDelivery.selfdelivery_point = AppConfigService.options.config.selfdelivery_points[0];
            // }
        });
    };

    var getDeliveryRegions = function ()
    {
        options.loading.deliveryRegions = true;
        var data = {
            request: 'getDeliveryRegions'
        };

        RequestService.processRequest("/api/handler.php", data).success(function (response) {
            options.loading.deliveryRegions = false;
            options.deliveryRegions = response.data;
            orderData.delivery_region = options.deliveryRegions[0].id;
        });
    };

    var getWorkingHours = function ()
    {
        if ( typeof ( orderData.selectedDelivery.selfdelivery_point ) == "undefined" )
        {
            return;
        }

        options.loading.timePicker = true;

        var data = {
            request: 'getWorkhours',
            selfdeliveryPoint: orderData.selectedDelivery.selfdelivery_point.id
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;
            options.schedule = response.schedule;
            options.exceptions = response.exceptions;
            options.loading.timePicker = false;
        });
    };

    var prepareWorkingHours = function ( date )
    {
        options.timePeriods = [];

        options.timePeriods.push('');

        date = new Date(date);
        var day = date.getDate();
        var month = date.getMonth();
        var dayNum = date.getDay();

        var dateObject = new Date();
        var nowDate = {
            dayNum: dateObject.getDay(),
            day: dateObject.getDate(),
            month: dateObject.getMonth(),
            year: dateObject.getFullYear()
        };

        var isToday = false;
        if ( nowDate.day == day )
        {
            isToday = true;
        }

        var schedule = explode( ";", options.schedule );

        var start_hour = 0;
        var end_hour = 0;
        var interval = 0;

        switch( dayNum )
        {
            case 0:
            {
                start_hour = schedule[7];
                end_hour = schedule[8];
                interval = schedule[9];

                break;
            }

            case 6:
            {
                start_hour = schedule[4];
                end_hour = schedule[5];
                interval = schedule[6];

                break;
            }

            default:
            {
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }

        }

        for ( i in options.exceptions )
        {

            var exc_day = options.exceptions[i].date.substr( 0, 2 );
            var exc_month = options.exceptions[i].date.substr( 3, 2 );

            if ( parseInt( exc_day ) == day && parseInt( exc_month ) == month + 1 )
            {
                schedule = explode( ";", options.exceptions[i].exc_schedule );
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }
        }

        var build_time = schedule[0];

        var m = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( start_hour.substr( 0, 2 ) ), parseInt( start_hour.substr( 3, 2 ) ) ) );
        var mEnd = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( end_hour.substr( 0, 2 ) ), parseInt( end_hour.substr( 3, 2 ) ) ) );
        var now = moment( AppConfigService.options.config.server_time );

        var i = 0;

        while ( ( m.unix() + interval * 60 ) <= mEnd.unix() )
        {

            var hours = ( m.hours() < 10 ) ? "0" + m.hours() : m.hours();
            var minutes = ( m.minutes() < 10 ) ? "0" + m.minutes() : m.minutes();
            m.add( interval, 'minutes' );

            var calculatedValue = now.unix() + build_time * 60;

            if ( m.unix() >= calculatedValue )
            {
                var time = hours + ":" + minutes + " - " + ( ( m.hours() < 10 ) ? "0" + m.hours() : m.hours() ) + ":" + ( ( m.minutes() < 10 ) ? "0" + m.minutes() : m.minutes() );
                if ( i == 0 )
                {
                    orderData.time = time;
                }

                options.timePeriods.push( time );
                i++;
            }
        }

        orderData.time = options.timePeriods[0];
    };

    var beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate )
    {
        switch ( $viewType )
        {
            case 'year':
            {
                renderYearView ( $datePickerDates );
                break;
            }

            case 'month':
            {
                renderMonthView ( $datePickerDates );
                break;
            }

            case 'day':
            {
                renderDayView ( $datePickerDates );
                break;
            }
        }
    };

    var renderYearView = function ( $datePickerDates )
    {
        var currentDate = new Date();

        for ( var i in $datePickerDates )
        {
            var datePickerDate = new Date( $datePickerDates[i].utcDateValue );
            if ( currentDate.getYear() > datePickerDate.getYear() )
            {
                $datePickerDates[i].selectable = false;
            }
        }
    };

    var renderMonthView = function ( $datePickerDates )
    {
        var currentDate = new Date();

        for ( var i in $datePickerDates )
        {
            var datePickerDate = new Date( $datePickerDates[i].utcDateValue );
            var isSelectable = true;

            if ( currentDate.getFullYear() > datePickerDate.getFullYear() )
            {
                isSelectable = false;
            }

            if ( currentDate.getFullYear() == datePickerDate.getFullYear() && currentDate.getMonth() > datePickerDate.getMonth() )
            {
                isSelectable = false;
            }

            $datePickerDates[i].selectable = isSelectable;
        }
    };

    var renderDayView = function ( $datePickerDates )
    {
        var currentDate = new Date();
        currentDate.setHours( 0, 0, 0, 0 );

        for ( var i in $datePickerDates )
        {
            var datePickerDate = new Date( $datePickerDates[i].utcDateValue );
            datePickerDate.setHours( 0, 0, 0, 0 );

            if ( currentDate > datePickerDate )
            {
                $datePickerDates[i].selectable = false;
            }
        }
    };

    var checkPromoCode = function ()
    {
        var code = orderData.promo_code;
        var data = {
            request: 'checkPromo',
            code: code
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;
            if ( typeof ( response.error ) !== "undefined" && response.error !== false )
            {
                orderData.promo_error = response.msg;
            } else {
                orderData.promo_error = false;
                orderData.promo_sum = response.sum;
                orderData.promo_type = response.type;
            }
        });
    };

    var rejectPromoCode = function ()
    {
        var data = {
            request: 'cancelPromo'
        };
        RequestService.processRequest("/api/handler.php", data).success( function ()
        {
            orderData.promo_sum = null;
            orderSum.promoSum = null;
            orderData.promo_code = null;
        });
    };

    var doPurchase = function ()
    {
        var items = getOrderItems();

        options.loading.forming = true;

        var date = new Date( orderData.date );
        var month =  date.getMonth() + 1;
        orderData.date_formatted = date.getDate() + '.' + month + '.' + date.getFullYear();

        options.formSubmitting = true;

        orderData.delivery_id = orderData.selectedDelivery.id;

        if ( orderData.selectedDelivery.selfdelivery )
        {
            orderData.selfdelivery_point = orderData.selectedDelivery.selfdelivery_point;
        }

        if ( orderData.noNeedText )
        {
            orderData.cardText = "";
        }

        var data = {
            request: 'orderOnPhoto',
            data: orderData,
            items: items
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;
            var redirect = response.redirect;

            if( response.result === false ) {
                $window.location.reload();
            }

            if ( redirect.indexOf( '://' ) == -1 ) {
                $location.url(redirect);
            } else {
                window.location.href = redirect;
            }

/*            if ( redirect.indexOf( '/' ) == -1 )
            {
                window.location.href = redirect;
            } else {
                var urlArr = redirect.split( "/" );
                urlArr.splice( 0, 2 );
                $location.url("/" + urlArr.join( "/" ) );
            }*/

            options.formSubmitting = false;
        });
    };

    var explode = function ( delimiter, string )
    {
        var emptyArray = {0: ''};

        if ( arguments.length != 2
            || typeof arguments[0] == 'undefined'
            || typeof arguments[1] == 'undefined' )
        {
            return null;
        }

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true )
        {
            delimiter = '1';
        }

        return string.toString().split( delimiter.toString() );
    };

    var saveImagesPath = function (images) {
        orderData.images = images;
    };

    return {
        clearOrderData: clearOrderData,
        temp: temp,

        options: function ()
        {
            return options;
        },
        orderData: function ()
        {
            return orderData;
        },
        orderSum: function ()
        {
            return orderSum;
        },

        getDateTimePickerConfig: getDateTimePickerConfig,

        getDeliveryTypes: getDeliveryTypes,
        getDeliveryRegions: getDeliveryRegions,

        getWorkingHours: getWorkingHours,
        prepareWorkingHours: prepareWorkingHours,
        beforeCalendarRender: beforeCalendarRender,

        checkPromoCode: checkPromoCode,
        rejectPromoCode: rejectPromoCode,

        isCardInAdditionalGoods: isCardInAdditionalGoods,
        getOrderItems: getOrderItems,

        doPurchase: doPurchase,
        saveImagesPath: saveImagesPath,
        setMeta: setMeta
    }
}

module.exports = BouquetOnPhotoService;