function AppService ($rootScope, AppConfigService) {
  'ngInject'

  var initApp = function () {
    return AppConfigService.options.isInit ? true : AppConfigService.init()
  }

  return {
    initApp: initApp,
  }
}

module.exports = AppService