function NotFoundController ( NotFoundService )
{
    "ngInject";

    var vm = this;
    vm.notFoundService = NotFoundService;

    vm.init = function ()
    {
        vm.notFoundService.init();
    };

    vm.init();
}

module.exports = NotFoundController;