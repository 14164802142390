function MetaService ($rootScope, $window, $location, $interpolate, AppConfigService) {
    'ngInject';

    var setMetaTags = function ( tagData )
    {
        if (typeof(tagData.title) != "undefined"){

            let documentTitleInterpolate = $interpolate(tagData.title)
            let documentTitle = documentTitleInterpolate(AppConfigService.options.config)

            $window.document.getElementsByName('title')[0].content = documentTitle
            $window.document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle)
            $window.document.querySelector('meta[name="twitter:title"]').setAttribute('content', documentTitle)
            $rootScope.title = documentTitle
        }

        if (typeof(tagData.description) != "undefined"){
            let documentDescriptionInterpolate = $interpolate(tagData.description)
            let documentDescription = documentDescriptionInterpolate(AppConfigService.options.config)

            $window.document.getElementsByName('description')[0].content = documentDescription//tagData.description;
            $window.document.querySelector('meta[property="og:description"]').setAttribute('content', documentDescription)
            $window.document.querySelector('meta[name="twitter:description"]').setAttribute('content', documentDescription)
        }

        if (typeof(tagData.keywords) != "undefined"){
            let documentKeywordsInterpolate = $interpolate(tagData.keywords)
            let documentKeywords = documentKeywordsInterpolate(AppConfigService.options.config)
            $window.document.getElementsByName('keywords')[0].content = documentKeywords
        }

        if(typeof(tagData.img) != "undefined"){
            let documentTitleInterpolate = $interpolate(tagData.title)
            let documentTitle = documentTitleInterpolate(AppConfigService.options.config)

            imgUrl = $location.protocol() + '://' + $location.host() + tagData.img;
            $window.document.querySelector('meta[property="og:image"]').setAttribute('content', imgUrl);
            $window.document.querySelector('meta[property="og:image:alt"]').setAttribute('content', documentTitle);

            $window.document.querySelector('meta[name="twitter:image:src"]').setAttribute('content', imgUrl);
        }

        $window.document.querySelector('link[rel="canonical"]').setAttribute('href', $location.absUrl().split('?')[0]);
        $window.document.querySelector('meta[property="og:url"]').setAttribute('content', $location.absUrl());
        $window.document.querySelector('meta[name="twitter:url"]').setAttribute('content', $location.absUrl());

        var element = $window.document.getElementsByName('fragment')[0];
        if (typeof(element) != "undefined")
            element.parentNode.removeChild(element);
    };

    return {
        setMetaTags: setMetaTags
    }
}

module.exports = MetaService;