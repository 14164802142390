function CatalogItemController ( CatalogItemService, CartService, SuperFastOrderService )
{
    "ngInject";

    var vm = this;
    vm.catalogItemService = CatalogItemService;
    vm.superFastOrder = SuperFastOrderService;
    vm.cartService = CartService;

    vm.catalogItem = CatalogItemService.options;

    vm.init = function ()
    {
        vm.catalogItemService.init();
    };

    vm.init();
}
module.exports = CatalogItemController;