function ContentController ( $scope, ContentService, AppConfigService )
{
    "ngInject";

    var vm = this;

    vm.pageContent = undefined;
    vm.contentService = ContentService;
    vm.appConfig = AppConfigService;
    vm.res = null;
    vm.coords = null;
    vm._map = null;

    vm.init = function () {
        vm.contentService.loadPage().then( function( pageContent ) {
            vm.pageContent = pageContent
        });
    };

    vm.ifLast = function(last) {
        if(!last){
            return;
        }
        vm._map.$target.geoObjects.each(function(obj){
            vm._map.$target.setBounds(obj.getBounds(),{
                checkZoomRange: true,
                zoomMargin: 10
            });
            return false;
        });
    };

    vm.afterInit = function(map) {
        vm._map = map;
        vm.coords = ContentService.getMapCoordinates();
    };

    vm.init();
}

module.exports = ContentController;