/*
     Контроллер для элемента каталога
*/

function CatalogItemListController ( $rootScope, $anchorScroll, $timeout, $scope, $location, CatalogItemListService, CartService ) {

    "ngInject";

    var vm = this;

    vm.catalog = CatalogItemListService;
    vm.cartService = CartService;

    $scope.$watch(function () {
        return sessionStorage['itemId'];
    }, function (newVal, oldVal) {
        $location.hash(newVal);
        $timeout(function () {
            $anchorScroll();
        });
        sessionStorage.clear();
    }, true);

    /*
        Метод для "ленивой" подгрузки элементов каталога
     */
    vm.lazyLoadItems = function ()
    {
        vm.catalog.lazyLoadItems();
    };

    /*
        Старый метод для загрузки всех элементов каталога
     */
    vm.loadFilterRange = function ()
    {

    };

    vm.initCatalogList = function ( catalogId, setMetaData )
    {
        vm.catalog.initCatalogList( catalogId, setMetaData );
    };

    $rootScope.$on('catalog-filter-apply', function () {
        vm.catalog.doFilterCatalog()
    })
}

module.exports = CatalogItemListController;