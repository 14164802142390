function BannersService ($q, RequestService, $routeParams )
{
    "ngInject";

    var options = {
        items: undefined,
        slides: []
    };

    var init = function ()
    {
        load().then( function ( items )
        {
            options.items = items;
            checkBanners(items);
        });
    };

    var load = function ()
    {
        var deferred = $q.defer();

        var data = {
            request: 'getBanners',
            bannerPlaces: 'top_banner'
        };
        RequestService.processRequest("/api/handler.php", data).then( function ( response )
        {
            response = response.data.data;
            if ( typeof( response[0] ) != "undefined" && response.length > 0 )
            {
                options.items = response;
                deferred.resolve( options.items );
            }

            options.items = undefined;
            deferred.reject( options.items );

        }, function ( error )
        {
            options.items = undefined;
            deferred.reject( error );
        });

        options.items = deferred.promise;

        return $q.when( options.items );
    };

    var checkBanners = function (items) {
        options.slides = [];
        if( typeof(items) != "undefined" ) {
            items.forEach(function (item) {
                if (item.allowed_pages !== undefined) {
                    if (item.allowed_pages.indexOf(parseInt($routeParams.categoryId)) + 1) {
                        options.slides.push(item);
                    }
                } else {
                    options.slides.push(item);
                }
            });
        }
    };

    return {
        options: options,
        init: init,
        checkBanners: checkBanners
    }
}

module.exports = BannersService;