function SelectFirstIfOnlyOne() {
    "ngInject";

    return {
        restrict: 'A',
        require: 'select',
        link: function (scope, elem, attrs, ctrl) {
            let regex = /in (.+?)(?: |$)/; //Here
            let collection = regex.exec(attrs.ngOptions)[1]; // And here

            scope.$watchCollection(collection, function (values) {
                if (angular.isDefined(values) && values.length === 1) {
                    scope.$evalAsync(function () {
                        ctrl.ngModelCtrl.$setViewValue(values[0]);
                        ctrl.ngModelCtrl.$render();
                    });
                }
            });
        }
    };
}



module.exports = SelectFirstIfOnlyOne;