function CartService( $window, $q, $rootScope, $location, AppConfigService, RequestService, Notification)
{
    "ngInject";
    var cart = {
        items : [],
        sum : 0
    };

    var options = {
        schedule: {},
        exceptions: [],
        timePeriods: [],
        deliveryRegions: [],
        deliveryTypes: [],
        paymentMethods: [],
        formSubmitting: false,
        loading: {
            price: false,
            forming: false,
            deliveryRegions: false,
            timePicker: false
        },
        lastSeenItems: [],
        dateTimePickerConfig: {
            startView: 'day',
            minView: 'day'
        }
    };

    var orderData = {
        selectedDelivery: {},
        is_anonymous: false,
        delivery_customer_name: "",
        delivery_client_name: "",
        customer_phone_number: "",
        phone_number: "",
        delivery_region: null,
        delivery_address: "",
        date: null,
        time: null,
        promo_code: null,
        promo_error: null,
        promo_type: null,
        promo_sum: null,
        payment_id: null,
        parent_payment_id: null,
        order_comment: "",
        needPhoto: false
    };

    var orderSum = {
        discountId: null,
        promoSum: 0
    };

    var termsOfUse = {
        isCheckTerms: true
    }

    /*
        Метод для восстановления состояния корзины
     */
    var restoreCartState = function ()
    {
        if (typeof(localStorage[ 'cart' ] ) !== 'undefined') {
            var refresh = angular.fromJson( localStorage[ 'cart' ] );
            var idItems = [];

            refresh.items.forEach(function (item) {
                var quantity = (item.quantity !== 'undefined') ? item.quantity : 1;
                idItems.push({
                    "id": item.id,
                    "quantity": quantity
                });
            });

            updateListItems(idItems);
        }

        saveCartState();
    };

    /*
        Метод для сохранения состояния корзины
     */
    var saveCartState = function ()
    {
        localStorage.setItem( 'cart', angular.toJson( cart ) );
    };

    /*
     * Метод для проверки наличия товара в корзине
     * @param item cartItem
     * @return cartItem || false
     */
    var isCartItemExist = function ( item )
    {
        return cart.items.find( function ( element ) {
            return element.id === item.id;
        });
    };

    /*
     * Метод для добавления товара в корзину
     */
    var addItemToCart = function( item )
    {
        var foundElement = isCartItemExist( item );

        if ( foundElement == -1 || typeof( foundElement ) == 'undefined' ) {
            if ( typeof( item.quantity ) == 'undefined' )
            {
                item.quantity = 1;
            }

            cart.items.push( item );
        } else {
            foundElement.quantity++;
        }

        if ( item.type === 'card') {
            item.noNeedText = false;
            item.text = '';
        }

        sendGtagInfo(item, 'add_to_cart');
        //sendPixelInfo(item, 'AddToCart') //off Facebook pixel after 24.02.22

        Notification.success("Товар добавлен");

        calculateCartSum();

        $rootScope.$broadcast('savecartstate');
        $rootScope.$emit('quantitychange');
    };

    var sendPixelInfo = function (item, type) {
        fbq('track', type, {
            value: 10.00,
            currency: 'RUB',
            content_name: item.name,
            content_type: 'product',
            content_ids: item.id
        });
    };

    var sendGtagInfo = function (item, type) {

        gtag('event', type, {
            "items": [
                {
                    "id": item.id,
                    "name": item.name,
                    "list_name": item.category,
                    "category": item.category,
                    "quantity": 1,
                    "price": item.price
                }
            ]
        });
    };
    /*
        Метод для подсчета суммы товаров в корзине
     */
    var calculateCartSum = function ()
    {
        var totalSum = 0;
        cart.items.forEach( function ( item )
        {
            totalSum += ( parseFloat( item.price ) * parseInt( item.quantity ) );
        });

        cart.sum = totalSum;
    };

    /*
        Метод возвращает сумму товаров в корзине
     */
    var getCartSum = function()
    {
        return cart.sum;
    };

    /*
        Метод возвращает список товаров находящихся в корзине
     */
    var getItems = function ()
    {
        return cart.items;
    };

    /*
        Метод обновляет список товаров находящихся в корзине
    */
    var updateListItems = function (idItems)
    {
        var data = {
            request: 'updateListItemsCart',
            idItems: idItems
        };

       return RequestService.processRequest("/api/handler.php", data).success(function ( response )
        {
            cart = response.data;
            saveCartState();
        });

    }

    /*
        Метод возвращает кол-во товаров в корзине
     */
    var getItemsCount = function ()
    {
        return cart.items.length;
    };

    /*
        Метод устанавливает кол-во товара в корзине
     */
    var setItemQuantity = function ( item, quantity )
    {
        if ( item.quantity === 1 && quantity < item.quantity) {
            removeItemFromCart(item);
            return;
        }

        sendGtagInfo(item, 'add_to_cart');
        item.quantity = quantity;
        calculateCartSum();

        $rootScope.$broadcast('savecartstate');
        $rootScope.$emit('quantitychange');
    };

    /*
        Метод удаляет товар из корзины
     */
    var removeItemFromCart = function ( item )
    {
        var index = cart.items.indexOf(item);
        if (index > -1) cart.items.splice(index, 1);
        sendGtagInfo(item, 'remove_from_cart');
        Notification.info("Товар удален");
        calculateCartSum();
        $rootScope.$broadcast('savecartstate');
        $rootScope.$emit('quantitychange');
    };

    /*
        Метод очищает корзину
     */
    var clearCart = function ()
    {
        cart = {
            items : [],
            sum : 0
        };

        $rootScope.$broadcast('savecartstate');
    };

    var getDateTimePickerConfig = function ()
    {
        return options.dateTimePickerConfig;
    };

    var clearOrderData = function ()
    {
        var deferred = $q.defer();

        orderData = {
            selectedDelivery: {},
            is_anonymous: false,
            delivery_customer_name: "",
            delivery_client_name: "",
            customer_phone_number: "",
            phone_number: "",
            delivery_region: null,
            delivery_address: "",
            date: null,
            time: null,
            promo_code: null,
            promo_error: null,
            promo_type: null,
            promo_sum: null,
            payment_id: null,
            cardText: "",
            noNeedText: false,
            order_comment: ""
        };
        clearCart();
        deferred.resolve( true );

        return deferred.promise;
    };

    var isCardInAdditionalGoods = function ()
    {
        var result = false;

        return result;
    };

    var getOrderItems = function ()
    {
        var orderItems = [];

        cart.items.map(function (item) {
            orderItems.push(item);
        });

        return orderItems;
    };

    var updateOrderPrice = function ()
    {
        if ( options.loading.catalogItem || ( typeof ( orderData.selectedDelivery ) == 'undefined' ) )
        {
            return false;
        }

        options.loading.price = true;

        var items = getOrderItems();

        var data = {
            request: 'getOrderPrice',
            items: items,
            deliveryId: orderData.selectedDelivery.id,
            deliveryRegion: orderData.delivery_region && orderData.delivery_region.id,
            promoCode: orderData.promo_code,
            needPhoto: orderData.needPhoto
        };

        RequestService.processRequest("/api/handler.php", data).success(function ( response )
        {
            options.loading.price = false;
            orderSum = Object.assign( orderSum, response.data);

            if ( orderSum.discountId == 892 && orderData.promo_sum == null )
            {
                checkPromoCode();
            }
        });
    };



    var getDeliveryTypes = function ()
    {
        options.loading.deliveryTypes = true;
        var data = {
            request: 'getDeliveryTypes'
        };

        return RequestService.processRequest("/api/handler.php", data).success(function (response)
        {
            options.loading.deliveryTypes = false;
            options.deliveryTypes = response.data;

            if ( options.deliveryTypes.length > 0 )
            {
                orderData.selectedDelivery = options.deliveryTypes[1];
            }

            // if( AppConfigService.options.config.selfdelivery_points.length > 0 )
            // {
            //     orderData.selectedDelivery.selfdelivery_point = AppConfigService.options.config.selfdelivery_points[0];
            // }
        });
    };

    var getDeliveryRegions = function ()
    {
        options.loading.deliveryRegions = true;

        var data = {
            request: 'getDeliveryRegions'
        };

        RequestService.processRequest("/api/handler.php", data).success(function (response) {
            options.loading.deliveryRegions = false;
            options.deliveryRegions = response.data;
            orderData.delivery_region = options.deliveryRegions[0].id;
        });
    };

    var getWorkingHours = function ()
    {
        if ( typeof ( orderData.selectedDelivery.selfdelivery_point ) == "undefined" )
        {
            return;
        }

        options.loading.timePicker = true;
        var data = {
            request: 'getWorkhours',
            selfdeliveryPoint: orderData.selectedDelivery.selfdelivery_point.id
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;
            options.schedule = response.schedule;
            options.exceptions = response.exceptions;
            options.loading.timePicker = false;
        });
    };

    var prepareWorkingHours = function ( date )
    {
        options.timePeriods = [];

        options.timePeriods.push('');

        date = new Date(date);
        var day = date.getDate();
        var month = date.getMonth();
        var dayNum = date.getDay();

        var schedule = explode( ";", options.schedule );

        var start_hour = 0;
        var end_hour = 0;
        var interval = 0;

        switch( dayNum )
        {
            case 0:
            {
                start_hour = schedule[7];
                end_hour = schedule[8];
                interval = schedule[9];

                break;
            }

            case 6:
            {
                start_hour = schedule[4];
                end_hour = schedule[5];
                interval = schedule[6];

                break;
            }

            default:
            {
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }

        }

        for ( i in options.exceptions )
        {

            var exc_day = options.exceptions[i].date.substr( 0, 2 );
            var exc_month = options.exceptions[i].date.substr( 3, 2 );

            if ( parseInt( exc_day ) == day && parseInt( exc_month ) == month + 1 )
            {
                schedule = explode( ";", options.exceptions[i].exc_schedule );
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }
        }

        var build_time = schedule[0];

        var startDayTime = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( start_hour.substr( 0, 2 ) ), parseInt( start_hour.substr( 3, 2 ) ) ) );
        var endDayTime = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( end_hour.substr( 0, 2 ) ), parseInt( end_hour.substr( 3, 2 ) ) ) );
        var now = moment( AppConfigService.options.config.server_time );

        var i = 0;

        while ( ( startDayTime.unix() + interval * 60 ) <= endDayTime.unix() )
        {
            var hours = startDayTime.format('HH');
            var minutes = startDayTime.format('mm');

            startDayTime.add( interval, 'minutes' );

            var calculatedValue = now.unix() + build_time * 60;

            if ( startDayTime.unix() >= calculatedValue )
            {
                var time = hours + ":" + minutes + " - " + startDayTime.format('HH')  + ":" + startDayTime.format('mm');
                if ( i == 0 )
                {
                    orderData.time = time;
                }

                options.timePeriods.push( time );
                i++;
            }
        }

        orderData.time = options.timePeriods[0];
    };

    var beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate )
    {
        switch ( $viewType )
        {
            case 'year':
            {
                renderYearView ( $datePickerDates );
                break;
            }

            case 'month':
            {
                renderMonthView ( $datePickerDates );
                break;
            }

            case 'day':
            {
                renderDayView ( $datePickerDates );
                break;
            }
        }
    };

    var renderYearView = function ( $datePickerDates )
    {
        var currentDate = new Date();

        for ( var i in $datePickerDates )
        {
            var datePickerDate = new Date( $datePickerDates[i].utcDateValue );
            if ( currentDate.getYear() > datePickerDate.getYear() )
            {
                $datePickerDates[i].selectable = false;
            }
        }
    };

    var renderMonthView = function ( $datePickerDates )
    {
        var currentDate = new Date();

        for ( var i in $datePickerDates )
        {
            var datePickerDate = new Date( $datePickerDates[i].utcDateValue );
            var isSelectable = true;

            if ( currentDate.getFullYear() > datePickerDate.getFullYear() )
            {
                isSelectable = false;
            }

            if ( currentDate.getFullYear() == datePickerDate.getFullYear() && currentDate.getMonth() > datePickerDate.getMonth() )
            {
                isSelectable = false;
            }

            $datePickerDates[i].selectable = isSelectable;
        }
    };

    var renderDayView = function ( $datePickerDates )
    {
        var currentDate = new Date();
        currentDate.setHours( 0, 0, 0, 0 );

        for ( var i in $datePickerDates )
        {
            var datePickerDate = new Date( $datePickerDates[i].utcDateValue );
            datePickerDate.setHours( 0, 0, 0, 0 );

            if ( currentDate > datePickerDate )
            {
                $datePickerDates[i].selectable = false;
            }
        }
    };

    var checkPromoCode = function ()
    {
        var code = orderData.promo_code;
        var data = {
            request: 'checkPromo',
            code: code
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;
            if ( typeof ( response.error ) !== "undefined" && response.error !== false )
            {
                orderData.promo_error = response.msg;
            }
            else
            {
                orderData.promo_error = false;
                orderData.promo_sum = response.sum;
                orderData.promo_type = response.type;
            }
            updateOrderPrice();
        });
    };

    var rejectPromoCode = function ()
    {
        var data = {
            request: 'cancelPromo'
        };

        RequestService.processRequest("/api/handler.php", data).success( function ()
        {
            orderData.promo_sum = null;
            orderSum.promoSum = null;
            orderData.promo_code = null;
            updateOrderPrice();
        });
    };

    var getAvailablePayments = function () {
        if ( typeof( orderData.selectedDelivery ) == 'undefined' )
        {
            return false;
        }

        var data = {
            request: 'getPaymentMethodsForWebByDeliveryId',
            deliveryId: orderData.selectedDelivery.id
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            options.paymentMethods = preparePaymentsList(response.data);
        });
    };

    var preparePaymentsList = function (paymentsList, parentId) {
        var preparedList = []
        parentId = typeof(parentId) !== "undefined" ? parentId : null
        if (paymentsList.length > 0) {
            paymentsList.forEach(function(item, index, array) {
                if (typeof(item.payment_subtypes) !== "undefined" && item.payment_subtypes.length > 0) {
                    preparedList = preparedList.concat(preparePaymentsList(item.payment_subtypes, item.id))
                } else {
                    item = Object.assign(item, {parentId: parentId})
                    preparedList.push(item)
                }
            });
        }

        return preparedList
    }

    var doPurchase = function ()
    {
        var items = getOrderItems();

        options.loading.forming = true;

        var date = new Date( orderData.date );
        orderData.date_formatted = date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();

        options.formSubmitting = true;

        orderData.delivery_id = orderData.selectedDelivery.id;

        if ( orderData.selectedDelivery.selfdelivery )
        {
            orderData.selfdelivery_point = orderData.selectedDelivery.selfdelivery_point;
        }

        var preparedCardText = '';

        items.forEach( function ( itemValue)
        {
            if (itemValue.type === 'card') {
                preparedCardText += itemValue.name + ': ' + itemValue.text + '; ';
            }
        });

        orderData.cardText = preparedCardText;

        var data = {
            request: 'formOrder',
            data: orderData,
            items: items
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;

            googleCommerce(response);
            yamarketCommerce(response);
            //pixelCommerce(response); //off Facebook pixel after 24.02.22

            var redirect = response.redirect;

            if( response.result === false ) {
                $window.location.reload();
            }

            if ( redirect.indexOf( '://' ) == -1 ) {
                $location.url(redirect);
            } else {
                window.location.href = redirect;
            }

            clearOrderData();

            options.formSubmitting = false;
        });
    };

    var googleCommerce = function(response) {

        var items = [];

        response.items.forEach(function(item){
            items.push({
                "id": item.id,
                "name": item.name,
                "list_name": item.category,
                "category": item.category,
                "quantity": item.quantity,
                "price": item.price
            });
        });

        gtag('event', 'purchase', {
            "transaction_id": response.order_number,
            "value": response.order_sum,
            "currency": "RUB",
            "shipping": response.delivery_price,
            "items" : items
            }
        );

        gtag('event', 'conversion', {
            'send_to': 'AW-1001962874/VCCoCKOFrZUBEPr64t0D',
            'value': response.order_sum,
            'currency': 'RUB',
            'transaction_id': response.order_number
        });
    };
/**/
    var pixelCommerce = function(response) {

        var pixelProductsList = [];
        response.items.forEach(function(item){
            pixelProductsList.push({
                "id": item.id,
                "name": item.name,
                "quantity": item.quantity,
                "price": item.price
            });
        });

        fbq('track', 'Purchase', {
            value: 10.00,
            currency: 'RUB',
            content_type: 'product',
            contents: pixelProductsList
        });
    };

    var yamarketCommerce = function(response) {

        var yandexProductsList = [];

        response.items.forEach(function(item){
            yandexProductsList.push({
                "id": item.id,
                "name": item.name,
                "list_name": item.category,
                "category": item.category,
                "quantity": item.quantity,
                "price": item.price
            });
        });

        if( typeof($window.dataLayer) != "undefined" ) {
            $window.dataLayer.push({
                "ecommerce": {
                    "purchase": {
                        "actionField": {
                            "id": response.order_number
                        },
                        "products": yandexProductsList
                    }
                }
            });
        }
    };



    var explode = function ( delimiter, string )
    {
        var emptyArray = {0: ''};

        if ( arguments.length != 2
            || typeof arguments[0] == 'undefined'
            || typeof arguments[1] == 'undefined' )
        {
            return null;
        }

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true )
        {
            delimiter = '1';
        }

        return string.toString().split( delimiter.toString() );
    };

    $rootScope.$on('quantitychange', updateOrderPrice);
    $rootScope.$on("restorecartstate", restoreCartState);
    $rootScope.$on("savecartstate", saveCartState);

    return {
        getItemsCountInCart : function ()
        {
            return getItemsCount();
        },

        restoreCart : function ()
        {
            return restoreCartState();
        },

        getCartSum : function ()
        {
            return getCartSum();
        },

        addToCart : function ( item )
        {
            addItemToCart( item );
        },

        getItems : function ()
        {
            return getItems();
        },

        setItemQuantity : function ( item, quantity ) {
            setItemQuantity( item, quantity );
        },

        removeItem : function ( item )
        {
            removeItemFromCart( item );
        },

        clearCart : function ()
        {
            clearCart();
        },

        clearOrderData: clearOrderData,

        options: function ()
        {
            return options;
        },
        orderData: function ()
        {
            return orderData;
        },
        orderSum: function ()
        {
            return orderSum;
        },

        getDateTimePickerConfig: getDateTimePickerConfig,

        updateOrderPrice: updateOrderPrice,
        getDeliveryTypes: getDeliveryTypes,
        getDeliveryRegions: getDeliveryRegions,

        getWorkingHours: getWorkingHours,
        prepareWorkingHours: prepareWorkingHours,
        beforeCalendarRender: beforeCalendarRender,

        checkPromoCode: checkPromoCode,
        rejectPromoCode: rejectPromoCode,

        isCardInAdditionalGoods: isCardInAdditionalGoods,
        getOrderItems: getOrderItems,
        getAvailablePayments: getAvailablePayments,

        doPurchase: doPurchase
    }
}

module.exports = CartService;
