function template () {
    return `   
         <div class="row">
            <div class="col-xs-6 orderby"> 
                <a title="по цене" href="#" ng-click="$ctrl.setOrder($event)">
                <span class="glyphicon" ng-class="{'glyphicon-sort-by-attributes': $ctrl.orderBy === 'asc', 'glyphicon-sort-by-attributes-alt': $ctrl.orderBy === 'desc'}"></span> 
                <span>{{ $ctrl.title }}</span></a>
            </div>    
         </div>
  `
}

function CatalogOrderByController ($rootScope, $location) {
    'ngInject'

    let vm = this
    vm.orderBy = 'asc';

    vm.$onInit = function () {
        let searchParameters = $location.search();

        if(typeof searchParameters[`order_filter[${vm.name}]`] !== 'undefined') {
            vm.orderBy = searchParameters[`order_filter[${vm.name}]`];
        }
    }

    vm.setOrder = function ($event) {
        $event.preventDefault();
        vm.orderBy = (vm.orderBy === 'asc' ? 'desc' : 'asc')
        $location.search(`order_filter[${vm.name}]`, vm.orderBy);
        $rootScope.$broadcast('catalog-filter-apply')
    }
}
module.exports = {
    template: template(),
    controller: CatalogOrderByController,
    bindings: {
        title: '@',
        name: '@'
    }
}