function AdditionalGoodsService ( $q, $routeParams, $uibModal, RequestService, CartService )
{
    "ngInject";

    var service = {
        items: [],
        categories: {},
        currentCategoryId: undefined
    };

    var windowInstance = undefined;

    var addItem = function ( item )
    {
        if ( isItemExist( item ) )
        {
            CartService.setItemQuantity(item, item.quantity + 1);
        }
        else
        {
            CartService.addToCart(item);
        }
    };

    var isItemExist = function ( item )
    {
        if ( typeof ( service.items[ service.currentCategoryId ] ) == "undefined")
        {
            service.items[ service.currentCategoryId ] = [];
        }

        return service.items[ service.currentCategoryId ].filter( function ( inArrayItem )
        {
            return inArrayItem.id == item.id;
        }).length;
    };

    var delItem = function ( item )
    {
        service.items[ service.currentCategoryId ] = service.items[ service.currentCategoryId ].filter( function ( inArrayItem )
        {
            return inArrayItem.id != item.id;
        });
    };

    var closeCategoryWindow = function ( timeout )
    {
        windowInstance.close();
    };

    var getItems = function ( categoryId )
    {
        var result = service.items.filter( function ( item )
        {
            return item.categoryId == categoryId;
        });

        return result[0].items;
    };

    var getCategorySum = function ( categoryId )
    {
        var categorySum = 0;

        service.items[ categoryId ].forEach( function ( element )
        {
            categorySum += element.price;
        });

        return categorySum;
    };

    var openCategoryWindow = function ( categoryId )
    {
        service.currentCategoryId = categoryId;
        windowInstance = $uibModal.open({
            ariaLabelledBy: "modal-title-top",
            ariaDescribedBy: "modal-body-top",
            controller: 'AdditionalGoodsController as additionalGoodsCtrl',
            templateUrl: "AdditionalCategoryItemsWindow.html",
            windowClass: 'goods-modal',
            windowTemplateUrl: '/angular_pages/includes/additionalGoodsModal.tpl'
        });
    };

    var getCategoriesList = function (cart)
    {
        var deferred = $q.defer();
        var data = {
            request: 'getMiscItems',
            currentItemId: $routeParams.itemId,
            isCart: cart
        };

        RequestService.processRequest("/api/handler.php", data).then(
            function success ( response )
            {
                response = response.data.data;
                service.categories = response;
                deferred.resolve( response );
            },
            function error ( response )
            {
                deferred.reject( response.status );
            }
        );

        return deferred.promise;
    };

    return {
        service: service,
        addItem: addItem,
        isItemExist: isItemExist,
        delItem: delItem,
        closeCategoryWindow: closeCategoryWindow,
        getItems: getItems,
        getCategorySum: getCategorySum,
        openCategoryWindow: openCategoryWindow,
        getCategoriesList:getCategoriesList
    };
}

module.exports = AdditionalGoodsService;