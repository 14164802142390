var angular = require( "angular" );

angular.module( "flowersApp" )
    .service( "AppConfigService", require( "./AppConfigService" ) )
    .service( "AppService", require( "./AppService" ) )

    .service( "MetaService", require( "./MetaService" ) )
    .service( "SuperFastOrderService", require( "./SuperFastOrderService" ))
    .service( "StatusCodeService", require( "./StatusCodeService" ) )
    .service( "BannersService", require( "./BannersService" ) )
    .service( "CategoriesService", require( "./CategoriesService" ) )
    .service( "ContentService", require( "./ContentService" ) )
    .service( "NotFoundService", require( "./NotFoundService" ) )
    .service( "CatalogItemListService", require( "./CatalogItemListService" ) )
    .service( "PriceFilterService", require( "./PriceFilterService" ) )
    .service( "CatalogItemService", require( "./CatalogItemService" ) )
    .service( "AdditionalGoodsService", require( "./AdditionalGoodsService" ) )
    .service( "SuccessPaymentService", require( "./SuccessPaymentService" ) )
    .service( "CollectService", require( "./CollectService" ) )
    .service( "RequestService", require( "./RequestService" ) )
    .service( "MaintenanceService", require( "./MaintenanceService" ) )
    .service( "SearchService", require( "./SearchService" ) )
    .service( "BouquetOnPhotoService", require( "./BouquetOnPhotoService" ) )
    .factory( "CartService", require( "./CartFactory" ) )
    .service( "ReviewsService", require( "./ReviewsService" ) )
    .service( "FreeDeliveryRegionsService", require( "./FreeDeliveryRegionsService" ) )
    .service( "$exceptionHandler", require( "./ExceptionHandlerService" ) )
    .service("RandomSeoLinksService", require("./RandomSeoLinksService"))
    .service("SeoMenuService", require("./SeoMenuService"))

    .service( "CatalogFilterComponentService", require("./CatalogFilterComponentService") )
;
