function PriceFilterService ( $routeParams, RequestService )
{
    "ngInject";

    var filterValue = [];
    var options = {
        min: 0,
        max: 0,
        step: 10,
        value: [0, 0],
        range: true,
        categoryId: null,
        initialized: false
    };

    var init = function ( categoryId )
    {
        options.categoryId = ( !categoryId ? parseInt( $routeParams.categoryId ) : parseInt( categoryId ) );

        if( typeof( options.categoryId ) != "number" )
        {
            return;
        }

        var data = {
            request: 'getCategoryRange',
            categoryId: options.categoryId
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            response = response.data;
            options.max = response.max;
            options.min = response.min;

            options.value = [ parseInt( response.min ), parseInt( response.max ) ];

            options.initialized = true;
        });
    };

    return {
        filterValue: filterValue,
        options: options,
        init: init
    }
}

module.exports = PriceFilterService;