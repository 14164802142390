function SuccessPaymentController ( SuccessPaymentService )
{
    "ngInject";

    var vm = this;
    vm.successPaymentService = SuccessPaymentService;
    vm.values = vm.successPaymentService.values;

    vm.init = function ()
    {
        vm.successPaymentService.init();
    }

    vm.init();
}

module.exports = SuccessPaymentController;