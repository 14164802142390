function IndexController ( $scope, $timeout, MetaService, SuperFastOrderService, StatusCodeService, AppConfigService, CategoriesService, SearchService, CatalogItemListService )
{
    'ngInject';

    var vm = this;

    vm.superFastOrder = SuperFastOrderService;

    vm.initIndex = function ()
    {
        SearchService.options.searchString = "";
        SearchService.options.result = null;
        CatalogItemListService.options.id = undefined;
        CatalogItemListService.options.items = [];

        StatusCodeService.setStatusCode("200");
        MetaService.setMetaTags({
            title: "Салон " + AppConfigService.options.config.shop_name + ", Доставка цветов " + AppConfigService.options.config.city,
            description: AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords,
            img: AppConfigService.options.config.logo
        });

        CategoriesService.states.activeItem = undefined;
    };

    $scope.$watch(
        function ()
        {
            return SearchService.options.searchString;
        },
        function ( newValue )
        {
            if (newValue == "") {
                $timeout(function () {
                    SearchService.options.result = null;
                    angular.element(document.querySelector('.dropdown')).removeClass('open');
                }, 1000)

            }
        });

}

module.exports = IndexController;