function CollectController ( CollectService )
{
    "ngInject";

    var vm = this;

    vm.options = CollectService;

    vm.options.loadCollections();
}

module.exports = CollectController;