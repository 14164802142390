function SearchController ( MetaService, StatusCodeService, AppConfigService, SearchService )
{
    'ngInject';

    var vm = this;

    vm.search = SearchService;

    vm.init = function ()
    {
        StatusCodeService.setStatusCode("200");
        MetaService.setMetaTags({
            title: "Поиск, Доставка цветов " + AppConfigService.options.config.city,
            description: AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords,
            img: AppConfigService.options.config.logo
        });
    };

}

module.exports = SearchController;