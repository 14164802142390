function CatalogFilterComponentController (
  $rootScope, $scope, $location, CatalogFilterComponentService) {
  'ngInject'

  let vm = this

  vm.catalogFilterFields = []
  vm.onLoad = true
  vm.showFiltersOnMobile = false

  /**
   *  Метод для проверки существования поля
   * @param fieldName
   * @returns {boolean}
   */
  vm.isFieldExist = function (fieldName) {
    return vm.catalogFilterFields.findIndex(function (item) {
      return item.name === fieldName
    }) !== -1
  }

  /**
   * Метод для получения поля
   * @param fieldName
   * @returns {T}
   */
  vm.getField = function (fieldName) {
    return vm.catalogFilterFields.find(function (item) {
      return item.name === fieldName
    })
  }

  /**
   * Метод для подготовки строки параметров
   * @param paramsToPrepare
   * @returns {string}
   */
  vm.prepareFilterParams = function (paramsToPrepare) {
    let preparedParams = []

    paramsToPrepare.forEach(function (item, index) {
      switch (item.type) {
        case 'relation': {
          if ((preparedFieldParam = vm.prepareRelationParam(item)) !== false) {
            preparedParams.push(preparedFieldParam)
          }
          break
        }
        case 'boolean': {
          if (item.selected) {
            preparedParams.push(vm.prepareBooleanParam(item))
          }
          break
        }
        case 'price': {
          preparedParams.push(vm.preparePriceParam(item))
          break
        }

      }
    })

    preparedParams = preparedParams.join('&').trim('&')

    return preparedParams
  }

  /**
   * Метод для подготовки поля типа "Price"
   * @param paramData
   * @returns {string}
   */
  vm.preparePriceParam = function (paramData) {
    let preparedParam = []

    preparedParam.push(
      `fields_filter[${paramData.name}][0]=${paramData.selected.min}`)
    preparedParam.push(
      `fields_filter[${paramData.name}][1]=${paramData.selected.max}`)

    return preparedParam.join('&').trim('&')
  }

  /**
   * Метод для подготовки поля типа "Relation"
   * @param paramData
   * @returns {string|boolean}
   */
  vm.prepareRelationParam = function (paramData) {
    if (paramData.selected.length === 0) {
      return false
    }

    let preparedParam = []

    let paramName = `fields_filter[${paramData.name}][eq]`
    paramName += (paramData.multiple ? `[]` : ``)

    paramData.selected.forEach(function (item, index) {
      preparedParam.push(`${paramName}=${item}`)
    })

    return preparedParam.join('&').trim('&')
  }

  /**
   * Метод для подготовки поля типа  "Boolean"
   * @param paramData
   * @returns {string}
   */
  vm.prepareBooleanParam = function (paramData) {
    return `fields_filter[${paramData.name}][eq]=` +
      (paramData.selected ? '1' : '0')
  }

  /**
   * Метод для выполнения фильтрации
   */
  vm.doFilter = function () {
    $location.search(vm.prepareFilterParams(vm.catalogFilterFields)).replace()
    $rootScope.$broadcast('catalog-filter-apply')
  }

  /**
   * Метод для сброса параметров фильтрации
   */
  vm.reset = function () {
    vm.catalogFilterFields.forEach(function (item, index) {
      switch (item.type) {
        case 'boolean': {
          item.selected = false
          break
        }
        case 'relation': {
          item.selected = []
          break
        }
        case 'price': {
          item.selected = item.options
          break
        }
      }
    })

    vm.doFilter()
  }

  /**
   * Метод для получения кол-ва выбранных фильтров
   * @returns {number}
   */
  vm.getSelectedFiltersCount = function () {
    let selectedFiltersCount = 0
    vm.catalogFilterFields.forEach(function (item, index) {
      switch (item.type) {
        case 'boolean': {
          if (item.selected || item.selected == 1) {
            selectedFiltersCount++
          }
          break
        }
        case 'relation': {
          if (item.selected.length > 0) {
            selectedFiltersCount++
          }
          break
        }
        case 'price': {
          if (item.selected.min != item.options.min || item.selected.max != item.options.max) {
            selectedFiltersCount++
          }
          break
        }
      }
    })

    return selectedFiltersCount
  }

  /**
   *  Метод выполняющийся при инициализации компонента
   */
  vm.$onInit = function () {
    vm.onLoad = false

    CatalogFilterComponentService.getFilterFields(vm.catalogId).
      then(function (response) {
        vm.catalogFilterFields = response.data
        vm.onLoad = false
      })
  }
}

module.exports = CatalogFilterComponentController