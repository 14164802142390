function AppConfigService ( $window, $metrika, $injector, $location, RequestService, CategoriesService)
{
    "ngInject";

    var options = {
            isInit: false,
            config: null,
            categories: null,
            ya_coords: null,
            maintenanceMode: false
        };

    var initializeSiteMetrics = function ()
    {
        if ( options.config.metrika_id != "" )
        {
            $metrika.counterConfigs.push({
                id: options.config.metrika_id,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: true,
                ecommerce: "dataLayer"
            });
            $metrika.defaultCounterId = options.config.metrika_id;

            $metrika.insertMetrika();
        }

        if ( options.config.google_analytics_id != "" )
        {
            gtag('js', new Date());
            gtag('config', options.config.google_analytics_id);
        }
/* off Facebook pixel after 24.02.22
        if (options.config.facebook_id != null)
        {
            fbq('init', options.config.facebook_id);
            fbq('track', 'PageView');
        }

 */
    };

    var init = function () {

        loadConfig().then(function(){
            if (options.maintenanceMode) {
                options.isInit = false;
                $location.url('/maintenance');
                return;
            }

            var ContentService;
            if (!ContentService) { ContentService = $injector.get("ContentService"); }
            ContentService.getMenu();

            initializeSiteMetrics();

            CategoriesService.getList().then(function (items) {
                options.categories = null;
                options.categories = items;
            }).then(function () {
                var BannersService;
                if (!BannersService) { BannersService = $injector.get("BannersService"); }
                BannersService.init();
            });
            var CartService;
            if (!CartService) { CartService = $injector.get("CartService"); }
            CartService.restoreCart();
            var MetaService;
            if (!MetaService) { MetaService = $injector.get("MetaService"); }
            MetaService.setMetaTags({
                title: "Салон " + options.config.shop_name + ", Доставка цветов по городу " + options.config.city,
                description: options.config.system_description,
                keywords: options.config.system_keywords,
                cityName: options.config.city
            });
            $("#loader").hide();
            options.isInit = true;
        });
    };

    var loadConfig = function () {

        var data = {
            request: "getConfig"
        };

        return RequestService.processRequest("/api/handler.php", data).success( function ( response )
        {
            if (response.data.site_status) {
                options.maintenanceMode = true;
            }

            options.config = response.data;
        });
    };

    return {
        options: options,
        init: init
    }
}

module.exports = AppConfigService;