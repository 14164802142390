function MaintenanceService(AppConfigService, MetaService, StatusCodeService, $timeout)
{
    "ngInject";

    var setMeta = function () {
        MetaService.setMetaTags({
            title: "Maintenance Page, Доставка цветов " + AppConfigService.options.config.city,
            description: AppConfigService.options.config.system_description,
            keywords: AppConfigService.options.config.system_keywords,
            img: AppConfigService.options.config.logo
        });
        $timeout(function () {
            StatusCodeService.setStatusCode("503");
        }, 500);

    };

    return {
        setMeta: setMeta
    };
}

module.exports = MaintenanceService;