function CollectModalController ( $uibModalInstance, CollectService, AppConfigService )
{
    "ngInject";
    var vm = this;

    vm.options = CollectService;
    vm.appConfig = AppConfigService;

    vm.closeModal = function ()
    {
        $uibModalInstance.close();
    };
}

module.exports = CollectModalController;