function ReviewsService ( $http, AppConfigService, MetaService ) {
  "ngInject";

  var getReviews = function ( page ) {
    setMeta();
    var reviewsShopToken = AppConfigService.options.config.reviews_shop_token;

    if( reviewsShopToken != '' ) {
      if( isNaN( page ) ) {
        page = 1;
      }

      return $http({
        url: 'https://xn----btbkclpc6dce3bm1c.xn--p1ai/api/shops/' + reviewsShopToken + '/feedbacks',
        method: 'GET',
        params: {
          page: page,
        },
        cache: true
      })
    }

    return [];
  }

  var setMeta = function (title, description, keywords) {

    var metaData = {
      title: "Отзывы покупателей, Доставляем букеты по городу " + AppConfigService.options.config.city,
      description: "Отзывы покупателей на доставку цветов в городе " + AppConfigService.options.config.city + " от салона " + AppConfigService.options.config.shop_name,
      keywords: AppConfigService.options.config.system_keywords
    }

    MetaService.setMetaTags(metaData);
  };


  return {
    getReviews: getReviews,
    setMeta: setMeta
  }
}

module.exports = ReviewsService;